
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.social-icons {
  position: fixed;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
}

@media (max-width: 768px) {
  .social-icons,
  .social-icons.mobile {
    top: auto;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: row;
  }
}

.social-icons a {
  background-color: #fff;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.social-icons a:hover,
.social-icons a:focus {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.social-icons img {
  width: 24px;
  height: auto;
}

@media (max-width: 768px) {
  .social-icons a {
    margin: 0 8px;
  }
}
