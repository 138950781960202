/*customer notification*/
.customer-container {
    margin-top: 40px !important;
    padding: 0 15px 15px;
    min-height: calc(100vh - 140px);
    font-family: "DM Sans", sans-serif;
}

.customer-container .title {
    font-size: 1rem;
    font-weight: 500;
    color: #434ce6;
}

.customer-container .title {
    color: #434ce6;
    /* text-decoration: none; */
}

.customer-container .card {
    border-radius: 10px !important;
}

.customer-input-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
}

.customer-input-container label {
    font-family: "DM Sans", sans-serif !important;
    color: #444444 !important;
    font-size: 0.9rem !important;
    font-weight: 500 !important;
    margin: 0 !important;
}

.customer-input-container input {
    padding: 10px;
    color: #000000 !important;
    border: 1px solid #d0cece;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
}

.customer-input-container .textarea {
    max-height: none;
    padding: 10px;
    color: #000000 !important;
    border: 1px solid #d0cece;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
}

.customer-custom-select {
    color: #000000 !important;
    border: 1px solid #d0cece;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
}

/* .customer-custom-select>div>div {
    max-height: 40px;
} */

.customer-custom-select > div {
    /* max-height: 40px; */
    border: none;
    border-radius: 10px;
}

.customer-button-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.customer-custom-card {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.customer-custom-tabs {
    border: 2px solid #cbcbcb;
    border-radius: 10px;
}

.customer-custom-tabs [class*="col-"] {
    padding: 0;
}

.customer-custom-tabs [class*="col-"]:first-child .customer-custom-item {
    border-radius: 10px 10px 0 0;
}

.customer-custom-tabs [class*="col-"]:last-child .customer-custom-item {
    border-radius: 0 0 10px 10px;
}

.customer-custom-item {
    padding: 20px 10px;
    cursor: pointer;
    background-color: #ffffff;
    color: #434ce6;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.customer-custom-item svg {
    color: #434ce6;
}

.customer-custom-item p {
    font-weight: 600;
    padding: 0 10px;
    margin: 0;
}

.customer-content-container {
    font-family: "DM Sans", sans-serif !important;
    height: 100%;
    width: 100%;
    margin: auto;
    padding: 20px;
    border: 2px solid #cbcbcb;
    background-color: #ffffff;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.customer-content-title {
    font-size: 1rem;
    font-weight: 600;
    color: #434ce6;
}

.customer-phone-input-container {
    font-family: "DM Sans", sans-serif !important;
    border: 1px solid #d0cece !important;
    background-color: #ffffff !important;
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 0 !important;
}

.customer-phone-input-container .form-control {
    margin-left: 40px !important;
    background-color: transparent !important;
    border: none !important;
    padding: 10px !important;
}

.customer-flag-search-btn {
    background-color: #ffffff !important;
    border: none !important;
    border-right: 1px solid #d0cece !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    padding: 10px;
    flex: 1;
}

.customer-flag-search-btn .selected-flag:focus,
.customer-flag-search-btn .selected-flag:hover {
    background-color: transparent !important;
    border: none !important;
}

.notification-profile-tabs {
    border-radius: 10px;
}

.customer-custom-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.notification-tab-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px 10px;
    background-color: #ffffff;
    color: #00182e;
    font-size: 1rem;
    font-weight: 600;
    border: 2px solid #cbcbcb;
    cursor: pointer;
}

.notification-tab-item svg {
    color: #434ce6;
}

.notification-tab-item:first-child {
    border-radius: 10px 0 0 10px;
    border-right: 0;
}

.notification-tab-item:last-child {
    border-radius: 0 10px 10px 0;
    border-left: 0;
}

.highlight-tab {
    border: 2px solid #434ce6;
    background-color: #434ce6;
    color: #ffffff;
}

.highlight-tab svg {
    color: #ffffff;
}

.notification-tab-item p {
    margin: 0;
}

.upload-file-btn {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "DM Sans", sans-serif;
}

.upload-file-name {
    border: 1px solid #d0cece;
    border-radius: 0 10px 10px 0;
    height: 100%;
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 5px;
}

.export-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.customer-search-box {
    border: 1px solid #d0cece;
    border-radius: 10px;
    height: 40px;
    width: 100%;
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.customer-search-box input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0 10px;
    box-shadow: none !important;
}

.customer-search-box svg {
    position: absolute;
    right: 10px;
}

.export-btn-container {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.export-btn-container button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    text-transform: none;
}

/* ----------------------------------------- */

.custom-file-upload-btn-container {
    display: flex;
    gap: 10px;
    overflow: hidden;
    white-space: nowrap;
}

.custom-file-upload-btn-container label {
    width: 200px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-family: "DM Sans", sans-serif;
    font-size: 0.9rem !important;
    font-weight: 500;
    background-color: #ffffff;
    color: #434ce6 !important;
    border-radius: 10px;
    border: 1px solid #434ce6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    margin-bottom: 0px !important;
}

.custom-file-upload-btn-container span {
    color: #000000 !important;
    font-size: 0.9rem !important;
    font-weight: 400 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.customer-notification-radio-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    margin-bottom: 30px;
}

.customer-notification-radio-header {
    font-size: 1.2rem;
    font-weight: normal;
    color: #000000;
    margin: 0;
}

.customer-notification-radio-header::after {
    content: ":";
    margin: 0 5px;
}

.customer-notification-radio-group .radio-group {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 1rem;
    font-weight: 500;
    color: #000000;
}

.customer-notification-radio-group .radio-group input {
    position: relative;
    width: 20px;
    height: 20px;
    border: 2px solid #cbcbcb;
    border-radius: 50%;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.customer-notification-radio-group .radio-group input:checked {
    background-color: #434ce6;
    border: 2px solid #434ce6;
}

.customer-notification-radio-group .radio-group input:checked::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
}

.customer-notification-radio-group .radio-group label {
    color: #444444;
    font-size: 0.9rem;
    font-weight: 500;
    margin: 0;
    font-family: "DM Sans", sans-serif;
}

.filter-dropdown {
    width: 200px;
    margin-right: 10px;
    border-radius: 10px !important;
    border: 1px solid #d0cece !important;
    background-color: #ffffff;
    box-shadow: none !important;
    font-family: "DM Sans", sans-serif !important;
}

@media (max-width: 768px) {
    .customer-content-details {
        padding: 0 !important;
        margin-top: 20px;
    }

    .notification-tab-item:first-child {
        border-radius: 10px 10px 0 0;
        border: 2px solid #cbcbcb;
        border-bottom: 0;
    }

    .notification-tab-item:last-child {
        border-radius: 0 0 10px 10px;
        border: 2px solid #cbcbcb;
        border-top: 0;
    }

    .export-btn-wrapper {
        justify-content: center;
    }
}
