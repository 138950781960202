.table-payment {
    width: 100%;
    border-collapse: collapse;
}

.table-payment th {
    font-weight: bold !important;
    text-align: center !important;
    font-size: 12px !important;
}

.table-payment td {
    text-align: center !important;
    font-size: 12px;
    word-wrap: break-word;
    width: fit-content;
}

.content-dashboard-payment {
    font-family: 'DM Sans', Arial, sans-serif;
}

.content-dashboard-payment .title {
    font-size: 1rem;
    font-weight: 500;
    color: #434CE6;
}

.payment-data-card {
    border-radius: 10px !important;
    font-family: 'DM Sans', Arial, sans-serif;
}

.payment-data-card .card-body {
    padding: 15px !important;
}

.payment-card-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.payment-card-title-container h5 {
    font-family: 'DM Sans', Arial, sans-serif;
    text-transform: none;
    font-weight: 600;
    color: #000000;
    margin: 0;
}

.payment-header-btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.payment-header-btn-container button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    text-transform: none;
}

.export-xls-btn,
.export-xls-btn:hover {
    background-color: #207841;
}

.export-pdf-btn,
.export-pdf-btn:hover {
    background-color: #CF2020;
}

.payment-mode-select input {
    width: 100% !important;
    height: 100% !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}

.transparent-save-btn {
    background-color: #207841 !important;
}

.payment-modal-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.payment-modal-header h5 {
    font-family: 'DM Sans', Arial, sans-serif;
    font-weight: 600;
    color: #000000;
}

.payment-modal-close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
}