.content-dashboard {
    font-family: 'DM Sans', sans-serif;
}

.content-dashboard .title {
    font-size: 1.2rem;
    font-weight: 500;
    color: #434CE6;
}

.title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.tile-title {
    text-transform: none;
    font-size: 24px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 0;
}

.create-bill-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #434CE6;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    text-transform: none;
    margin: 0;
}