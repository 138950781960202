.login-page{

  .card-login{
    border-radius: $border-radius-large;
    box-shadow: none;
    .btn-wd{
      min-width: 180px;
    }

    .logo-container{
      width: 65px;
      margin: 0 auto;
      margin-bottom: 55px;

      img{
        width: 100%;
      }
    }

    .input-group:last-child{
      margin-bottom: 40px;
    }

    &.card-plain{
      @include input-coloured-bg($opacity-5, $white-color, $white-color, $transparent-bg, $opacity-1, $opacity-2);

      .input-group-addon,
      .form-group.no-border .input-group-addon,
      .input-group.no-border .input-group-addon{
        color: $opacity-8;
      }
    }
  }
  .login-logo{
    width: 30px;
    height: 30px;
  }
  .link{
    font-size: 10px;
    color: $white-color;
    text-decoration: none;
  }
}

.full-page{
  align-items: center;
  min-height: 100vh;
  & > .footer{
    position: relative;
    z-index: 4;
  }

  &.section-image{
    // position: initial;
  }

  .footer{
    position: absolute;
    width: 100%;
    bottom: 0;

    .container{
      color: $white-color;
    }
  }

  .full-page-background{
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }

  &:after{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    background-color: $white-color;
    background-image: url("../../../../images/login_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .pricing-page{
    .description{
      margin-bottom: 65px;
    }
  }

  .register-page{
    & > .container {
      margin-top: 5vh;
    }
    .info-horizontal{
      padding: 0px 0px 20px;
    }

    .info-horizontal{
      text-align: left !important;

      .icon{
        margin-top: 0;

        >i{
          font-size: 2em;
        }

        &.icon-circle{
          width: 65px;
          height: 65px;
          max-width: 65px;
          margin-top: 8px;

          i{
            display: table;
            margin: 0 auto;
            line-height: 3.5;
            font-size: 1.9em;
          }
        }
      }
      .description{
        overflow: hidden;
      }

    }
  }
}

.register-page,
.login-page,
.lock-page {
  .navbar {
    .navbar-collapse {
      .nav-item .nav-link {
        text-transform: capitalize;
        color: $black-color !important;
        i{
          margin-right: 4px;
        }
      }
    }

    &.navbar-transparent {
      border-bottom: 1px solid transparent;
      margin-top: 25px;
    }

    .navbar-brand {
      color: $white-color !important;
    }
  }
}

.register-page,
.login-page,
.lock-page {
  width: 100%;
}
.password .input-group-text{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  background-color: transparent !important;
  border: none !important;
  position: absolute;
  top:5px;
  display: flex;
  // right: 1px;
  // border: 2px solid;
}

.forgot-pass{
  float:right;

  & a{
    color: $black-color;
  }
}
.btn-custom{
  background-color: $green;
  text-transform: capitalize;
   &:hover{
    background-color: $green !important;
   }
} 

.btn-custom-outline{
  background-color: transparent;
  border: 1px solid $green;
  text-transform: capitalize;
  color: $green;
   &:hover{
    background-color: $green !important;
    color: $white-color;
   }
}

.or-option{
  color: $link-disabled-color;
  text-align: center;
}

.link-text{
justify-content: right;  

& a{
  color: $new-dark-blue;
  font-weight: 600;
  &:hover{
    text-decoration: none;
  }
}
}
.right-section{
  background-color: $new-dark-blue;
  min-height: 100vh;
  border-top-left-radius: 3em;
  border-bottom-left-radius: 3em;
 
  & .header{
    color: $white-color;
    font-weight: 600;
  }
}

.social-img{
  width: 30px;
  height: 30px;
}

.form-center{
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-header{
  & h4{
    font-weight: 600;
  }
}

.text-size-contact-xs{
  font-size: 12px;
}

.react-tel-input{
  & .form-control {
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    color: #66615b;
    line-height: normal;
    font-size: 14px;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    box-shadow: none;
    padding: 10px 10px 10px 48px;
    height: initial;
    width: 100%;
  }
} 
.text-size-small{
 font-size: 12px;
}
.custom-checkbox-box{
  & .tc{
    color: $new-dark-blue;
  }
}
.terms-page{
  background-color: $white-color;
  width: 100%;
  height: 100vh;
  z-index: 2;
  & h1{
    text-align: center;
    background-color: $light-gray;
    margin: 0;
    padding: 10px;
    font-weight: 500;
  }
}

.terms-p{
  & p{
    font-size: 18px;
   }
}

.section-customize{
 & h2{
  font-weight: 600;
 }
 & ol li, p{
  font-size: 18px;
 }
}
ol.custom-marker {
  counter-reset: list;
}

ol.custom-marker {
  &  li {
    list-style: none;
    counter-increment: list;
  }
}
.section-customize{
  & .parens-around.lower-roman {
    & li::marker{
      content: "(" counter(list, lower-roman) ")\a0";
  } 
  }
}
.text-highlight{
  font-size: 20px;
}
.font-dark-blue{
  color: $new-dark-blue;
  font-weight:$font-weight-semi;
}
.text-radio{
  & .form-check-label{
    font-size: 15px;
  }
}
.datetime-icon{
  position: absolute;
  right: 20px;
  bottom: 12px;
  color: $dark-gray;
  cursor: pointer;
}
.customize-autocompete{
  & .p-autocomplete-input{
    padding: 5px !important;
  }
  & .p-inputtext{
    font-size: 10px;
  }

}
.cutomize-form-control{
  font-size: 11px;
 & .form-control
{
  font-size: 10px;
}

}
.btn-sm-padding{
  padding: 5px 15px;
}
.react-datepicker-wrapper{
  display: block;
}
.tble-scroll{
  max-height: 250PX;
  overflow-x: hidden;
  overflow-y:scroll;
}
.accordion {
  border: 1px solid $opacity-3;
  border-radius: 2px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid $opacity-3;
}

.accordion__button {
  background-color: $light-gray;
  color: $light-black;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
}

.accordion__button:hover {
  background-color: #ddd;
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 20px;
  animation: fadein 0.35s ease-in;
}

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}
.display-wrap{
  display: flex;
  justify-content: space-between;
}
.d-card-text{
  font-weight: 700;
  font-size: 20px;
  color: $white-color;
}
.lightblue-card-box{
  background-color: $card-box-light-blue;
}
.gray-card-box{
  background-color: $card-box-gray;
}
.blue-card-box{
  background-color: $card-box-blue;
}
.darkgreen-card-box{
  background-color: $card-box-dark-green;
}
.h-300{
  height: 300px !important;
}
.h-35{
  height: 35px;
}
.width-70{
  width: 70%;
}
.datepicker-customize{
  & .rdp-form-control{
    height: 35px;
    border-top-right-radius:4px !important; 
    border-bottom-right-radius:4px !important;
  }
  & .input-group{
    border-right:1px solid $medium-gray !important;
  }
}
.datepicker-customize{
  & .input-group-append{
    display: none;
  }
}
.card {
  & .poor-txt{
  font-size: 16px;
  color:$danger-color;
}
& .avrg-txt{
  font-size: 16px;
}
& .exclent-txt{
  font-size: 16px;
  color:$success-color;
}
}
.width-300{
  width: 300px;
}
.recharts-cartesian-axis-ticks{
  font-size: 12px;
}
.width-120{
  width:120px;
}
.list-style{
  & li{
    list-style: none;
    font-size:18px;
    padding: 5px 0;
  }
}
.listview{
  height: 120px;
  overflow-x: auto;
}
.custom-height-card{
  height: calc(100vh - 215px);
}
.width-90{
  width:90;
}
.tab-list{
  & li{
    font-size: 15px;
    padding: 10px;
    &:hover {
      background-color: $new-dark-blue;
      color:$white-color;
    }
    &:active{
      background-color: $new-dark-blue;
      color:$white-color;
    }
  }
}
.link-btn{
  font-weight: 600;
    font-size: 0.8571em;
    line-height: 1.35em;
    border: none;
    margin: 10px 1px;
    padding: 11px 22px;
    cursor: pointer;
    transition: all 150ms linear;
    justify-content: center;
    color: $white-color;
    & a{
      color: $white-color;
    }
}
.btn-signup-custom{
  background-color: $new-blue;
}
.btn-collapse-popup{
  position:absolute;
  top: 5px;
}
.table-border{
  border:1px solid $border-color-table;
}
.p-datatable{
& .p-datatable-thead{
  & th{
    background-color: $bg-color-table !important;
    border-width: 1px !important;
    padding: 5px !important;
  }
}
}
.p-datatable{
  & .p-datatable-tbody{
    & td{
      border-width: 1px !important;
      padding: 5px !important;
    }
  }
  }
.custom-list-item{
  padding: 0 10px;
}  
@media screen and (max-width: 1366px){
  .d-card-text{
    font-size: 14px;
   }
}
@media screen and (max-width: 1280px){
  .d-card-text{
    font-size: 8px;
   }
}
@media screen and (max-width: 991px){
  .register-page,
  .login-page,
  .lock-page {
    .navbar {
      &.bg-white {
        .navbar-toggler .navbar-toggler-bar.navbar-kebab {
          background: $default-color !important;
        }
        .navbar-collapse {
          .navbar-nav {
            .nav-item .nav-link {
              color: $default-color !important;
            }
          }
        }
        .navbar-brand {
          color: $default-color !important;
        }
      }

      &.navbar-transparent {
        .navbar-toggle {
          display: none;
        }
        .navbar-toggler {
          .navbar-toggler-bar.navbar-kebab {
            background: $white-color !important;
          }
        }
      }
    }
  }
  .login-page{

    .card-login{
      padding: 1rem;
    }
  }
  .right-link-text{
    & a{
      font-size: 14px;
    }
  }
  .form-center{
    width: 380px;
  }
  .navbar-expand-lg .navbar-nav{
    flex-direction: row;
  }
}
@media screen and (max-width: 480px){
  .card-header h4{
      font-size: 16px;
  }
  .text-size-contact-xs{
    font-size:9px;
  }
  .navbar-expand-lg .navbar-nav{
    flex-direction: row;
  }
  .login-logo{
    width: 23px;
    height: 23px;
  }
  .display-wrap{
    flex-direction: column;
  }
  .custom-margin-ml15{
    margin-left: 15px;
  }
  .card-mobile-100-ml-15{
    width: 100%;
    margin-left: 15px;
  }
}
