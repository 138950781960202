.subscription-cards-container {
    margin-top: 20px;
    font-family: "DM Sans", sans-serif;
}

.subscription-cards-container .card-body {
    padding: 20px !important;
}

.subscription-cards-container .card-body .row {
    margin-right: auto;
    margin-left: auto;
}

.active-plan-row {
    background: linear-gradient(135deg, #ebedff, #d4d7ff);
    border-radius: 10px;
    padding: 10px;
    align-items: center;
    border: none !important;
    /* background-size: 300% 300%; */
    /* animation: active-plan-stripe 12s linear infinite; */
}

@keyframes active-plan-stripe {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 100%;
    }
}

.current-plan-badge {
    display: inline-block;
    padding: 5px 10px;
    background-color: transparent;
    color: #434ce6;
    border: 2px solid #434ce6;
    border-radius: 50px;
    font-size: 1rem;
    font-weight: 600;
}

.active-plan-details {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin: 0 auto;
    padding: 10px;
}

.active-plan-details p {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
    color: black;
    font-family: "DM Sans", sans-serif;
}

.active-plan-details ul {
    list-style: none;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
    font-size: 0.9rem;
    font-weight: 500;
    color: #555555;
    margin: 0;
    font-family: "DM Sans", sans-serif;
}

.active-plan-details ul li span {
    color: black;
    font-weight: 600;
}

.active-plan-end-date p {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 500;
    color: #555555;
    font-family: "DM Sans", sans-serif;
}

.active-plan-end-date .end-date {
    font-size: 1.2rem;
    font-weight: 600;
    color: #434ce6;
    font-family: "DM Sans", sans-serif;
    margin: 0;
}

.card-container {
    margin: 40px 0;
}

.subscription-plan-card {
    height: 100%;
    border: 2px solid #e2e2e2;
    border-radius: 10px;
    padding: 40px 50px;
}

.current-active-plan-card {
    position: relative;
    border: 2px solid #434ce6 !important;
}

.active-plan-badge {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    padding: 5px 10px;
    background-color: #434ce6;
    color: #ffffff;
    border: 2px solid #434ce6;
    border-top: 0;
    border-right: 0;
    border-radius: 0 5px 0 10px;
    font-size: 0.8rem;
    font-weight: 600;
}

.subscription-plan-card .plan-name {
    font-size: 1.2rem;
    font-weight: 600;
    color: #000000;
    font-family: "DM Sans", sans-serif;
    margin: 0 0 20px;
}

.subscription-plan-card .plan-price {
    font-size: 1.1rem;
    font-weight: 600;
    color: #555555;
    font-family: "DM Sans", sans-serif;
    margin: 0;
}

.subscription-plan-card .plan-price span {
    font-size: 2rem;
    font-weight: 600;
    color: #434ce6;
    font-family: "DM Sans", sans-serif;
    margin: 0;
}

.subscription-plan-card .plan-features {
    font-size: 1rem;
    font-weight: 500;
    color: #555555;
    font-family: "DM Sans", sans-serif;
    margin: 40px 0;
}

.subscription-plan-card .plan-features span {
    font-size: 1.1rem;
    font-weight: 600;
    color: #000000;
    font-family: "DM Sans", sans-serif;
    margin: 0;
}

.plan-selection-btn button,
.upgrade-plan-btn button {
    width: 100%;
    background-color: #000000;
    color: #ffffff;
    font-family: "DM Sans", sans-serif;
    font-size: 1rem;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    outline: none;
    border: none;
    margin: 0 !important;
    padding: 0;
    transition: all 0.3s ease-in-out;
}

.plan-selection-btn button:hover,
.upgrade-plan-btn button:hover {
    background-color: #434ce6 !important;
    color: #ffffff;
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
    border-radius: 50px;
    border: none;
}

.upgrade-plan-modal .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.upgrade-plan-modal .header h3 {
    margin: 0;
    font-weight: 600;
    font-family: "DM Sans", sans-serif;
}

.upgrade-plan-modal .header svg {
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.upgrade-plan-details {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 5px;
    margin: 0;
}

.upgrade-plan-details p {
    font-size: 1rem;
    font-weight: 500;
    color: #000000;
    font-family: "DM Sans", sans-serif;
    margin: 0;
}

.upgrade-plan-details span {
    color: #434ce6;
    font-weight: 600;
    font-size: 1.2rem;
}

.terms-and-conditions-checkbox-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin: 20px 0;
}

.terms-and-conditions-checkbox-container label {
    font-size: 0.8rem;
    font-weight: 500;
    color: #000000;
    margin: 0;
    font-family: "DM Sans", sans-serif;
}

.upgrade-plan-btn {
    width: 100%;
    margin: 10px 0;
}

.upgrade-plan-btn .disabled {
    background-color: #555555;
    pointer-events: painted;
    cursor: not-allowed !important;
}

.company-details-card {
    padding: 20px 30px;
    border: 2px solid #e2e2e2;
    border-radius: 10px;
    margin: 0 10px;
}

.company-details-card h5 {
    font-weight: 600;
}

.usage-label {
    font-size: 1rem !important;
    font-weight: 500 !important;
    margin: 2px;
}

.usage-value {
    font-size: 1.1rem !important;
    font-weight: 500 !important;
    margin: 2px;
}

.usage-label span {
    color: #434ce6 !important;
    font-weight: 600 !important;
}

@media screen and (max-width: 768px) {
    .subscription-cards-container .card-body {
        padding: 10px !important;
    }

    .active-plan-row {
        padding: 30px 10px;
    }

    .active-plan-details ul {
        flex-direction: column;
        align-items: start;
        padding: 0;
    }

    .active-plan-details {
        padding: 10px 0;
        margin: 20px 0;
    }

    .subscription-cards-container .card-body .card-container {
        gap: 20px;
    }

    .subscription-cards-container .card-body .card-container [class*="col-"],
    .company-details-card .row [class*="col-"] {
        padding: 0;
    }

    .subscription-plan-card {
        padding: 20px;
    }

    .usage-card-title {
        font-size: 1.2rem;
    }

    .company-details-card {
        padding: 20px;
        margin: 0;
    }

    .billing-usage-data-container p {
        font-size: 0.9rem;
        font-weight: 500;
    }

    .usage-label {
        font-size: 0.9rem !important;
    }

    .usage-value {
        font-size: 1rem !important;
    }
}
