/* AdvancedFilterUI.css */
.filter-container {
  width: 100%;
  max-width: 100%;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 5px;
  width: 100%;
  /* margin-bottom: 20px; */
}

.input-group {
  display: flex;
  /* flex-direction: column; */
}

.filter-label {
  margin-bottom: 5px;
  font-weight: bold;
}

.filter-input {
  padding: 4px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 0;
}

.badge-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
}

.filter-badge {
  display: flex;
  align-items: center;
  background-image: linear-gradient(45deg, #434CE6, #434CE6);
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 20px;
  gap: 5px;
  font-size: 12px;
}

.badge-close {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
  color: white;
  font-size: 14px;
}

.badge-close:hover {
  color: #f3917f;
}

.apply-btn {
  padding: 0 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.apply-btn:hover {
  background-color: #218838;
}

.hover-row:hover {
  background-color: #f4f4f4;
  cursor: pointer;
}

.action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.filter-options {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
  align-items: flex-start;
}

.filter-option {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 0px;
}

.filter-checkbox {
  margin-right: 10px;
  cursor: pointer;
  margin: 6px 10px 10px 0px;
}

.filter-label {
  margin: 0;
  cursor: pointer;
}

.filter-actions {
  display: flex;
  justify-content: flex-end;
}

/* Custom scrollbar styles */
.scrollbar-custom {
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1);
  /* For Firefox */
}

.scrollbar-custom::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scrollbar-custom::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

.apply-btn-style {
  padding: 0px 10px;
  text-align: center;
  width: 50%;
  cursor: pointer;
  margin-bottom: 10px;
  width: 200px;
}

.apply-btn-style:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.loading-text {
  margin-left: 8px;
}

.numerical_filter {
  margin: 0px;
}

.advance-filter-card-body {
  font-family: 'DM Sans', sans-serif;
}

.advance-filter-card-body .header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  color: #000000;
  font-weight: bold;
  margin-bottom: 20px;
}

.header-row svg {
  cursor: pointer;
}

.filter-form.form-group label {
  font-size: 14px;
  font-weight: 500;
  color: #666666;
}

.filter-form .form-control {
  font-family: "DM Sans", sans-serif;
  font-size: 1em;
  border: 1px solid #D0CECE;
  border-radius: 10px;
  padding: 0.6rem !important;
  margin: 0px !important;
  color: #666666;
  background-color: #FFFFFF !important;
}

.filter-form .form-control:focus {
  box-shadow: none !important;
  border: 1px solid #D0CECE !important;
}

.filter-form .form-control.is-invalid:focus,
.filter-form .form-control.is-valid:focus {
  box-shadow: none !important;
}
