.title-route-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter-wrapper {
    margin: 30px 0 15px;
}

.cards-container {
    height: 400px;
    font-family: "DM Sans", sans-serif;
}

.chart-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chart-card canvas {
    font-family: "DM Sans", sans-serif !important;
}

.selling-cards {
    height: 100%;
    padding: 10px 10px 40px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.selling-cards:hover {
    scale: 1.01;
    box-shadow: 0 6px 10px -4px rgb(67 76 230 / 70%);
}

.selling-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.selling-header {
    width: 100%;
    color: #000000;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selling-header h5 {
    font-family: "DM Sans", sans-serif !important;
    font-weight: 600;
    margin: 0;
}

.selling-header .angle-arrow-svg {
    color: #434ce6;
    height: 60px;
    width: 60px;
}

.selling-data-label {
    color: #666666;
    font-size: 1.2rem;
    font-weight: 500;
}

.selling-data-label p {
    margin: 0;
}

.selling-data-value {
    color: #434ce6;
    font-size: 1.8rem;
    font-weight: 600;
}

.chart-container .card-body {
    padding: 10px 20px !important;
}

/* .bar-chart-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    padding: 20px;
} */

.bar-chart-wrapper {
    width: 100%;
    overflow-x: auto;
    margin-top: 10px;
}

.bar-chart-container {
    min-width: 600px;
}

/* ------- Best Selling Reports -------- */
.report-table-card .card-body {
    padding: 10px 0 !important;
}

.report-table-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}

.report-table-title h5 {
    font-weight: 600;
    text-transform: none;
    margin: 0;
}

.custom-report-table .p-datatable-thead > tr > th {
    padding-left: 10px !important;
}

.exclamation-svg {
    color: #dd2e2e;
}

.check-circle-svg {
    color: #199869;
}

.low-inventory-list {
    width: 100%;
    height: 100%;
}

.low-inventory-list p {
    color: #434ce6;
}

.reorder-stock-container .card-body {
    max-height: 500px;
}

.inventory-list-container {
    height: calc(100% - 50px);
}

.low-item-list {
    height: calc(100% - 30px);
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
    overflow-y: auto;
}

/* ------- Best Selling Reports -------- */

@media (max-width: 992px) {
    .cards-container {
        height: auto;
    }

    .cards-container > [class*="col-"] {
        padding-top: 20px;
    }

    .selling-cards {
        margin-top: 20px;
    }

    .chart-row {
        gap: 10px;
    }
}

@media (max-width: 768px) {
    .cards-container {
        height: auto;
    }

    .cards-container > [class*="col-"] {
        padding-top: 10px;
    }

    .selling-cards {
        margin-top: 10px;
    }

    .selling-card-body {
        gap: 20px;
    }

    .chart-row {
        gap: 10px;
    }

    .low-item-list{
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media (min-width: 1920px) and (max-width: 2560px) {
    .cards-container {
        height: auto;
    }
}
