.company-profile-card {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.company-profile-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

.search-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.search-input {
    font-family: 'DM Sans', sans-serif;
    width: 100%;
    max-width: 300px;
    padding: 0.5rem;
    color: #000000;
    font-size: 14px;
    border: 1px solid #B1B1B1;
    border-radius: 10px;
    outline: none;
}

.company-profile-tabs {
    border: 2px solid #CBCBCB;
    border-radius: 10px;
}

.company-profile-tabs [class*="col-"] {
    padding: 0;
}

.company-profile-item {
    padding: 20px 9px;
    cursor: pointer;
    background-color: #FFFFFF;
    color: #434CE6;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.company-profile-item svg {
    color: #434CE6;
}

.company-profile-item p {
    font-weight: 600;
    padding: 0 10px;
    margin: 0;
}

.company-profile-tabs [class*="col-"]:first-child .company-profile-item {
    border-radius: 10px 10px 0 0;
}

.company-profile-tabs [class*="col-"]:last-child .company-profile-item {
    border-radius: 0 0 10px 10px;
}

.highlight-tab {
    background-color: #434CE6;
    color: #FFFFFF;
}

.highlight-tab svg {
    color: #FFFFFF;
}

.profile-content-container {
    font-family: 'DM Sans', sans-serif !important;
    height: 100%;
    width: 100%;
    margin: auto;
    padding: 20px;
    border: 2px solid #CBCBCb;
    background-color: #FFFFFF;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-content-title {
    font-size: 1rem;
    font-weight: 600;
    color: #434CE6;
}

.custom-input-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
}

.custom-input-container label {
    color: #444444 !important;
    font-size: 0.9rem !important;
    font-weight: 500 !important;
    margin: 0 !important;
}

.custom-input-container input {
    padding: 10px;
    color: #000000 !important;
    border: 1px solid #D0CECE;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
}

.profile-button-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.profile-reset-btn {
    width: 100px;
    background-color: #FFFFFF !important;
    color: #434CE6 !important;
    border: 1px solid #434CE6 !important;
}

.profile-address-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.custom-phone-input-container {
    font-family: 'DM Sans', sans-serif !important;
    border: 1px solid #D0CECE !important;
    background-color: #FFFFFF !important;
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 0 !important;
}

.custom-phone-input-container .form-control {
    margin-left: 40px !important;
    background-color: transparent !important;
    border: none !important;
    padding: 10px !important;
}

.profile-flag-search-btn {
    background-color: #FFFFFF !important;
    border: none !important;
    border-right: 1px solid #D0CECE !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    padding: 10px;
    flex: 1;
}

.profile-flag-search-btn .selected-flag:focus,
.profile-flag-search-btn .selected-flag:hover {
    background-color: transparent !important;
    border: none !important;
}

.profile-custom-select {
    color: #000000 !important;
    border: 1px solid #D0CECE;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
}

/* .profile-custom-select>div>div {
    max-height: 40px;
} */

.profile-custom-select>div {
    /* max-height: 40px; */
    border: none;
    border-radius: 10px;
}

.profile-data-table-title {
    color: #000000;
    font-size: 1.5rem;
    font-weight: bold;
}

.profile-notification-setting-item {
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #D0CECE;
    border-radius: 10px;
    padding: 15px 20px;
    font-family: 'DM Sans', sans-serif !important;
}

.profile-notification-setting-item label {
    font-size: 0.8rem !important;
    color: #444444 !important;
    font-weight: 500 !important;
    margin: 0;
}

.profile-notification-setting-item svg {
    color: #434CE6;
}

/* Small Devices */
@media (max-width: 768px) {
    .company-profile-details {
        padding: 0 !important;
        margin-top: 20px;
    }

    .profile-address-form .row {
        gap: 20px;
    }
}

/* Large Devices */
@media (max-width: 992px) {
    .profile-address-form .row {
        gap: 20px;
    }
}

/* Extra Large Devices */
@media (min-width: 1920px) and (max-width: 2560px) {}