/* .bill-summary {
    background: #dcd9f1;
    border: 1px solid rgba(11, 6, 42, 0.3);
}

.hospital-name {
    font-size: 20px;
    color: #0B062A;
}

.tax-title {
    font-size: 12px;
    color: #0B062A;
}

.invoice-number {
    font-size: 12px;
}

.line-divider-dark {
    background: #0B062A;
    height: 4px;
    border: none;
}

.line-divider-light-dark {
    background: #0B062A;
    height: 2px;
    border: none;
}

.line-divider-light {
    background: #7B809A;
    height: 1px;
    border: none;
}

.title {
    font-size: 15px;
    color: #0B062A;
}

.description {
    font-size: 12px;
    color: #0B062A;
}

.text-bold {
    font-size: 12px;
    color: #0B062A;
    text-transform: uppercase;
}

.bill-table .ant-table,
.bill-table .ant-table-thead .ant-table-cell {
    background: transparent;
    font-size: 12px;
}

.bill-table .ant-table .ant-table-thead th {
    border-bottom: 2px solid #0B062A;
}

.bill-table .ant-table tr td {
    border-top: 2px solid #7B809A !important;
    border-bottom: none !important;
}

.bill-table .ant-table tr:hover td {
    background: transparent !important;
}

.discount-field {
    width: 150px;
}

.invoice-pdf {
    width: 70%;
    padding: 20px;
}

.invoice-pdf-table,
.invoice-pdf-table td,
.invoice-pdf-table th {
    border: 1px solid #0B062A;
    text-align: left;
}

.invoice-pdf-table {
    border-collapse: collapse;
    width: 100%;
}

.invoice-pdf-table th,
.invoice-pdf-table td {
    padding: 15px;
}

.invoice-pdf-table.total-table,
.invoice-pdf-table.total-table td,
.invoice-pdf-table.total-table th {
    border: none;
    font-weight: bold;
}

.invoice-pdf-table.total-table td:last-child,
.invoice-pdf-table th:last-child,
.invoice-pdf-table td:last-child {
    text-align: right;
}

.hospital-detail {
    text-align: center;
    font-weight: bold;
}

.patient-detail .patient-title {
    width: 35%;
    float: left;
}

.patient-detail .patient-title .colon {
    float: right;
    margin-right: 20px;
}

.patient-detail .patient-value {
    width: 65%;
} */
.text {
  font-size: 20px;
  font-weight: 500;
  color: #4272c4;
}

.autocomplete-Zindex {
  z-index: 2050 !important;
}

.product-seach-input {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
  width: auto;
  gap: 6px;
}

.filter-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: #242987;
}

.full-width {
  width: 100% !important;
}

.box-header1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.box-header2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

/* This will apply only to screens wider than 768px */
@media (min-width: 768px) {
  .box-header1,
  .box-header2 {
    top: -15px;
  }
}

.header1 {
  font-size: 20px;
  font-weight: bold;
}

.header2 {
  font-weight: 600;
}

.hospital-payment-header {
  text-align: center;
}

.hospital-payment-row {
  font-weight: 600;
}

.hr-line {
  border-top: 3px solid rgba(0, 0, 0, 0.758);
}

.hr-line-main {
  border-top: 5px solid rgba(0, 0, 0, 0.758);
}

.hr-line1 {
  border-top: 4px solid rgba(0, 0, 0, 0.758);
}

.hr-line2 {
  border-top: 2px solid rgba(0, 0, 0, 0.758);
}

/* Default style for mobile devices */
.labelMobile {
  text-align: left;
}

/* Style for medium and large screens */
@media (min-width: 768px) {
  .labelMobile {
    text-align: right;
  }
}

@media (max-width: 767px) {
  .hr-line2 {
    display: none;
  }
}

.formLabel {
  color: #252422 !important;
}

.bill-header {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.footer-row {
  text-align: left;
  /* Align text to the left */
}

.footer-value {
  text-align: right;
  /* Align text to the right */
}

/* General Styles */

.custom-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.custom-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.custom-col {
  flex: 1;
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
}

.custom-btn {
  margin: 0 5px;
}

/* Custom Dropdown */

.custom-dropdown {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  appearance: none;
}

@media (max-width: 768px) {
  .custom-row {
    flex-direction: column;
    align-items: center;
  }

  .custom-col {
    /* margin-bottom: 10px; */
    width: 100%;
  }

  .custom-col:last-child {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .custom-col:last-child {
    flex-direction: column;
    align-items: center;
  }

  .custom-btn {
    width: 100%;
    margin-bottom: 5px;
  }
}

.custom-autocomplete {
  width: 100%;
}

.rounded-button {
  border: none;
  /* padding: 10px; */
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  align-items: center;
  text-align: center;
  background-color: rgb(102, 97, 91);
  /* color: white !important; */
  color: #dddddd;
}

.rounded-button:hover {
  background-color: #2c2c2c;
}

.rounded-button.rotate {
  transform: rotate(180deg);
  transition: transform 0.5s ease-in-out;
}

.button-container {
  display: flex;
  justify-content: center;

  margin-top: -10px;
}

/* Mobile-first approach: Address comes first by default */
.flex-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.address-details {
  order: 1;
}

.payment-details {
  order: 2;
}

/* On medium and large screens: Payment details come first */
@media (min-width: 768px) {
  .address-details {
    order: 2;
  }

  .payment-details {
    order: 1;
  }
}

.text-right {
  text-align: center;
}

.fixed-width-right-align {
  width: 150px;
  text-align: right;
  display: inline-block;
}

.highlighted {
  color: "blue" !important;
  outline: 3px solid #b9cee4;
}

.custom-row-header {
  display: flex;
  flex-wrap: wrap;
}

.custom-col-header {
  padding: 2px;
}

.custom-col-header-8 {
  flex-basis: 66.66667%;
  /* 8/12 of the row */
}

.custom-col-header-4 {
  flex-basis: 33.33333%;
  /* 4/12 of the row */
}

.header1,
.header2 {
  margin: 0;
  /* Remove default paragraph margin */
}

.flex-container {
  transition: max-height 0.8s ease-in-out, opacity 0.8s ease-in-out;
  overflow: hidden;
}

.collapsed {
  max-height: 0;
  opacity: 0;
}

.expanded {
  max-height: 1000px;
  /* or whatever max height you expect */
  opacity: 1;
}

/* For large devices */
.addCustomerBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -20px;
  margin-top: auto;
  /* margin-right: -20px; */
}

/* For small devices */
@media (max-width: 767px) {
  .addCustomerBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 100%;
    max-width: 100%;
  }
}

.custom-width-input {
  width: 70%;
}

/* For small devices */
@media (max-width: 767px) {
  .custom-width-input {
    width: 100%;
  }
}

.form-container {
  position: relative;
}

/* Existing Styles */
.btn-collapse {
  position: absolute;
  top: 100%;
  /* right: 20px; */
  border: none;
  width: 1.6rem;
  height: 1.6rem;
  /* border: 1px solid gray; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.3rem;
}

/* Media Query for Mobile */
@media (max-width: 767px) {
  .btn-collapse {
    top: 101%;
  }
}

.toggle-button-discount {
  background-color: #007bff;
  /* Blue color */
  color: #ffffff;
  /* White text color */
  padding: 0px 20px;
  /* Padding around the text */
  border: none;
  /* Remove default borders */
  border-radius: 5px;
  /* Rounded corners */
  cursor: pointer;
  /* Hand cursor on hover */
  transition: background-color 0.3s;
  /* Smooth transition for hover effect */
}

.toggle-button-discount:hover {
  background-color: #0056b3;
  /* Darker blue on hover */
}

.text-radio {
  justify-content: space-between;
}

.custom-tooltip {
  background-color: #ffffff;
  color: #000000;
}

.suggested-outer-box {
  display: flex;
  justify-content: center;
  padding: 15px;
}

/* Custom Tooltip CSSS

Author: Priyanshu Malaviya

*/

.custom-tooltip {
  position: absolute;
  top: 120%;
  /* Increase top distance to ensure visibility */
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(145deg, #6e48aa, #9d50bb);
  /* Stylish gradient background */
  color: #fff;
  text-align: center;
  border-radius: 8px;
  /* Rounded corners for a modern look */
  padding: 10px 20px;
  font-size: 15px;
  /* Slightly larger font for readability */
  font-weight: 500;
  /* Medium font weight */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
  /* More pronounced shadow */
  z-index: 1000;
  /* Ensure it's above most elements */
  white-space: nowrap;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
  transform: translateX(-50%) translateY(-10px);
  /* Initial transform for animation */
  opacity: 0;
  visibility: hidden;
}

/* Positioning adjustments for the tooltip arrow */
.custom-tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  /* Arrow at the bottom of the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
  /* Arrow color matches tooltip */
}

.custom-tooltip-visible {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
  /* Final transform for animation */
}

.bill-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.create-bill-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #434ce6;
}

.bill-icons-template {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  min-width: fit-content;
  margin-top: 0px;
  margin-left: 20px;
}

@media (max-width: 767px) {
  .bill-icons-template {
    gap: 5px;
    justify-content: center;
  }
}

.filter-options {
  display: flex;
  align-items: center;
  justify-content: center;
}

.template-list-container {
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  /* padding: 0px 10px; */
  /* margin-top: 6px;
  margin-bottom: 6px; */
}

.list-item-template-style {
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 1rem;
  cursor: pointer;
  font-weight: 500;
}

.list-item-template-style:first-child {
  border-top: none;
}

.list-item-template-style:hover {
  background-color: #f5f5f5;
}

.list-item-template-style:active {
  background-color: #eaeaea;
}

/* Custom scrollbar styles */
.scrollbar-custom::-webkit-scrollbar {
  width: 5px !important;
}

.scrollbar-custom::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 20px !important;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 20px !important;
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

/* For Firefox */
.scrollbar-custom {
  scrollbar-width: thin !important;
  scrollbar-color: #888 #f1f1f1 !important;
}

.custom-input-box-invoice {
  display: block;
  margin: 0px 0px 0px 0;
  max-width: 150px;
  padding: 3px 15px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  margin-top: 10px;
  background-color: #e3e3e3;
  color: #66615b;
  cursor: not-allowed;
}

.invoice-container-box {
  padding: 10px 30px;
}

.invoice-detail-style {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.label-width {
  width: 60%;
  font-weight: bold;
  padding-left: 5px;
}

.invoice-readonly-input {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 20%;
}

.invoice-currency {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 20%;
}

.invoice-component {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invoice-select-payment {
  display: block;
  margin: 0px 0px 0px 0px;
  max-width: 200px;
  padding: 3px 20px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  margin-top: 10px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
  margin-left: 6px;
  cursor: pointer;
}

.custom-checkbox-label {
  cursor: pointer;
}

.bill-icon-invoice {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 10px;
  /* margin-top: 10px; */
}

.custom-input-box-invoice-discount {
  display: block;
  margin: 0px 0px 0px 0px;
  margin-top: -5px;
  max-width: 200px;
  padding: 3px 20px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  /* margin-top: 10px; */
}

.invoice-btn-style {
  padding: 0px 10px;
  text-align: center;
  width: 50%;
  cursor: pointer;
  /* margin-bottom: 10px; */
  width: 200px !important;
}

.invoice-btn-style:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.label-addcustomer {
  font-weight: bold;
}

/* .table-responsive {
} */

@media (max-width: 768px) {
  .table-responsive {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
}

.loadMore-billHistory {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.loadMore-billHistory-btn {
  line-height: 25px;
  height: 25px !important;
}

.btn-loadMore {
  line-height: 10px;
  height: 30px;
  text-align: center;
  margin: 0px !important;
}

/* styles.css */
.normalStyle {
  background-color: #f9f9f9;
  color: #333;
  border-bottom: 1px solid #ddd;
}

.alternateStyle {
  background-color: #2f4d74;
  color: #333;
  border-bottom: 1px solid #ccc;
}

#invoice-container-1 {
  padding-left: 25px;
  padding-right: 25px;
}

#invoice-container-2 {
  padding-left: 25px;
  padding-right: 25px;
}

#invoice-container-3 {
  padding-right: 25px;
  padding-left: 25px;
}

/* Template ICON style */
.action-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.icon-template-page {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.icon-template-page:hover {
  transform: scale(1.2);
  filter: brightness(1.2);
}

@media (max-width: 768px) {
  .action-icons {
    gap: 5px;
  }

  .icon-template-page {
    width: 20px;
    height: 20px;
  }

  .icon-template-page:hover {
    transform: scale(1.1);
    filter: brightness(1.1);
  }
}

/* Popover style */
.filter-popup-recent {
  position: absolute;
  top: 90%;
  transform: translateX(-50%) translateY(-10px);
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1000;
  width: 250px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  font-family: "DM Sans", sans-serif;
}

@media screen and (max-width: 768px) {
  .filter-popup-recent {
    position: absolute;
    top: 90%;
    /* left: 10px; */
    border: 1px solid #ccc;
    background-color: #fff;
    /* padding: 10px; */
    z-index: 1000;
    width: 250px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  }
}

.filter-container-recent .filter-popup-recent {
  opacity: 1;
  visibility: visible;
  transform: translateX(-40%) translateY(10px);
}

/* Arrow style */
.filter-popup-recent::before {
  content: "";
  position: absolute;
  top: -8px;
  right: 40px;
  transform: rotate(45deg);
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.1);
  z-index: -1;
}

.filter-popup-suggested {
  position: absolute;
  top: 90%;
  left: 60%;
  border: 1px solid #ccc;
  background-color: #fff;
  /* padding: 10px; */
  z-index: 1000;
  width: 250px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}

@media screen and (max-width: 768px) {
  .filter-popup-suggested {
    position: absolute;
    top: 90%;
    left: 20%;
    border: 1px solid #ccc;
    background-color: #fff;
    /* padding: 10px; */
    z-index: 1000;
    width: 250px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  }
}

.filter-container-suggested .filter-popup-suggested {
  opacity: 1;
  visibility: visible;
  transform: translateY(10px);
}

/* Arrow style */
.filter-popup-suggested::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 133px;
  transform: rotate(45deg);
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.1);
  z-index: -1;
}

/* Custom Checkbox Styles */

.reactCustomCheckboxContainer {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
}

.reactCustomCheckbox {
  width: 18px;
  height: 18px;
  border: 2px solid #007bff;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.reactCustomCheckbox.checked {
  background-color: #007bff;
  border-color: #007bff;
}

.reactCustomCheckboxCheckmark {
  color: #fff;
  font-size: 18px;
  display: none;
}

.reactCustomCheckbox.checked .reactCustomCheckboxCheckmark {
  display: block;
}

/* Speech Recognition Modal style
Author: Priyanshu Malaviya
 */
/* .speech-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.speech-icon {
  width: 100px;
  transition: transform 0.3s ease;
}

.speech-icon:hover {
  transform: scale(1.1);
}

.listening-animation {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
} */
.speech-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.speech-icon {
  width: 100px;
  position: relative;
  border-radius: 50%;
  cursor: pointer;
}

.speech-img {
  width: 100px !important;
  height: 100px !important;
}

.ripple-effect {
  position: absolute;
  border: 2px solid rgba(0, 123, 255, 0.5);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: ripple 2s infinite;
  pointer-events: none;
  top: 0;
  left: 0;
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

/* Autocomplete styling */
.autocomplete-item {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.autocomplete-details {
  display: block;
  width: 250px;
  font-size: 0.85em;
  color: #666;
  padding-left: 10px;
}

/* .autocomplete-item:hover .autocomplete-details {
  display: block;
} */

.product-autocomplete-item-card {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  font-family: "DM Sans", sans-serif;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-autocomplete-item-name {
  padding: 0 10px;
  font-size: 1em;
  font-weight: 600;
  color: #333;
}

.product-autocomplete-item-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 0.8em;
  color: #666;
  padding: 0 10px;
}

.product-autocomplete-item-card p {
  margin: 0;
}

.product-autocomplete-item-details p span {
  font-weight: 600;
}

/* ----------------- NEW UI ---------------- */

.add-customer-btn {
  height: 30px;
  /* width: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 5px 10px !important;
  font-size: 0.8rem;
  border-radius: 6px;
  background-color: #434ce6;
  color: #ffffff;
  font-family: "DM Sans", sans-serif;
  margin-top: 0;
}

.add-customer-btn svg {
  height: 15px;
  width: 15px;
}

.p-component,
.p-autocomplete-input {
  font-family: "DM Sans", Arial, sans-serif !important;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

.action-btn-col::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(160, 160, 160);
  border-radius: 10px;
}

.create-bill-container {
  font-family: "DM Sans", Arial, sans-serif;
  margin-top: 55px !important;
  padding: 0 15px 15px;
  min-height: calc(100vh - 140px);
}

.create-bill-container .title {
  font-size: 1.2rem;
  font-weight: 500;
  color: #434ce6;
}

.create-bill-container .card {
  border-radius: 10px !important;
  padding: 20px !important;
}

.title-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title-row h4 {
  text-transform: none;
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  margin: 0;
}

.purchase-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
}

.purchase-table thead th {
  font-size: 16px;
  font-weight: 600;
  color: #444444;
  padding: 10px 5px;
  text-align: center;
}

.purchase-table tbody td {
  font-size: 18px;
  font-weight: 600;
  padding: 10px 5px;
  text-align: center;
}

.product-name-col {
  min-width: 250px;
  width: 250px;
  /* max-width: 250px; */
  text-align: left !important;
}

.qty-col {
  min-width: 100px;
  width: 100px;
  /* max-width: 100px; */
}

.expiry-col {
  min-width: 100px;
  width: 100px;
  /* max-width: 100px; */
}

.rate-col {
  min-width: 90px;
  width: 90px;
  /* max-width: 100px; */
}

.basePrice-col {
  min-width: 93px;
  width: 110px;
  /* max-width: 100px; */
}

.mrp-col {
  min-width: 80px;
  width: 80px;
  /* max-width: 100px; */
}

.amount-col {
  min-width: 110px;
  width: 110px;
  /* max-width: 125px; */
  color: #242987;
}

.discount-col {
  min-width: 100px;
  width: 100px;
  /* max-width: 100px; */
}

.total-price-col {
  min-width: 125px;
  width: 125px;
  /* max-width: 125px; */
  color: #434ce6;
}

.sgst-col {
  min-width: 90px;
  width: 90px;
  /* max-width: 100px; */
}

.igst-col {
  min-width: 90px;
  width: 90px;
  /* max-width: 100px; */
}

.cgst-col {
  min-width: 90px;
  width: 90px;
  /* max-width: 100px; */
}

.net-amount-col {
  min-width: 115px;
  width: 115px;
  /* max-width: 125px; */
  color: #207841;
}

.action-btn-col {
  min-width: 50px;
  width: 50px;
  /* max-width: 50px; */
}

.custom-number-input {
  width: 75px !important;
  border-radius: 10px !important;
  border: 1px solid #c9c9c9 !important;
  text-align: center;
  padding: 0.5rem !important;
  color: #666666;
  -moz-appearance: textfield;
}

.custom-number-input::-webkit-inner-spin-button,
.custom-number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.create-bill-container .form-control {
  font-size: 14px !important;
  margin: 0 auto !important;
}

.delete-row-icon {
  color: #e63946;
}

.invoice-summary-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  border-radius: 10px;
  border: 1px solid #dcdcdc;
  padding: 20px 15px;
  margin-top: 20px;
}

.invoice-summary-container .title {
  text-transform: none;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  margin: 0;
}

.invoice-summary-data-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #444444;
  font-weight: bold;
}

.invoice-summary-data-row p {
  margin: 0;
}

.invoice-summary-grand-total-row {
  color: #000000;
}

.invoice-form-control {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.invoice-form-control label {
  font-weight: 500 !important;
  color: #444444 !important;
  margin-bottom: 5px;
}

.invoice-payment-mode-control {
  display: block;
  max-width: 200px;
  font-size: 1em;
  font-family: "DM Sans", sans-serif;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  outline: 0;
  border-radius: 10px !important;
  border: 1px solid #d0cece;
  padding: 0.6rem !important;
  margin: 0px !important;
  color: #666666;
  background-color: transparent !important;
}

.invoice-form-control .form-control {
  margin: 0 !important;
}

.invoice-form-control option {
  background-color: #ffffff;
}

.custom-manual-discount-control {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-manual-discount-control .manual-discount-input {
  border: 1px solid #d0cece;
  border-radius: 10px 0 0 10px;
  padding: 0.5rem;
  background-color: transparent;
  color: #666666;
  text-align: center;
  outline: none;
  -moz-appearance: textfield;
}

.custom-manual-discount-control .type-dropdown {
  font-family: "DM Sans", sans-serif;
  border-radius: 0 10px 10px 0;
  padding: 0.6rem;
  background-color: #e9eaff !important;
  color: #666666;
  border: none;
  cursor: pointer;
}

.invoice-comment-control {
  display: block;
  max-width: 200px;
  font-size: 1em;
  font-family: "DM Sans", sans-serif;
  border-radius: 10px !important;
  border: 1px solid #d0cece;
  padding: 0.5rem !important;
  margin: 0px !important;
  color: #666666;
  background-color: transparent !important;
}

.received-payment-label {
  font-size: 16px;
  font-weight: bold;
  color: #444444;
  margin: 0;
}

.manual-igst-input {
  max-width: 200px;
  font-size: 1em;
  font-family: "DM Sans", sans-serif;
  border: 1px solid #d0cece;
  padding: 0.6rem !important;
  height: 40px;
  width: 50px;
  margin: 0px !important;
  color: #666666;
  background-color: transparent !important;
  text-align: center;
  outline: none;
  -moz-appearance: textfield;
}

.manual-igst-input::-webkit-inner-spin-button,
.manual-igst-input::-webkit-outer-spin-button,
.manual-discount-input::-webkit-inner-spin-button,
.manual-discount-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.manual-igst-plus-btn,
.manual-igst-minus-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  margin: 0;
  background-color: #e9eaff !important;
  border: 1px solid #d0cece;
  box-shadow: none;
  color: #666666;
  border: none;
  cursor: pointer;
}

.manual-igst-plus-btn {
  border-radius: 0 10px 10px 0;
}

.manual-igst-minus-btn {
  border-radius: 10px 0 0 10px;
}

.custom-checkbox {
  width: 15px;
  height: 15px;
  margin-bottom: 4px;
}

.p-autocomplete.custom-autocomplete input {
  /* font-size: 1em; */
  font-family: "DM Sans", sans-serif !important;
  font-size: 14px !important;
  border: 1px solid #d0cece;
  border-radius: 10px !important;
  padding: 0.6rem !important;
  margin: 0px !important;
  color: #666666;
  background-color: #fbfbfb;
  outline: none;
}

.custom-autocomplete .p-autocomplete-input {
  padding: 0.4rem !important;
  width: 100%;
  font-size: 14px;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border: 1px solid #d0cece !important;
}

.autocomplete-item {
  font-family: "DM Sans", sans-serif !important;
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  .create-bill-container .card {
    padding: 10px !important;
  }

  .invoice-summary-container {
    padding: 10px 0;
  }
}

.form-control.custom-input-control {
  font-size: 1em;
  font-family: "DM Sans", sans-serif;
  border: 1px solid #d0cece;
  border-radius: 10px;
  padding: 0.6rem !important;
  margin: 0px !important;
  color: #666666;
  background-color: #fbfbfb !important;
}

.custom-primary-button.add-draft-btn {
  background-color: #3f3f3f;
}

/* ----------------- NEW UI ---------------- */

/* ----------------- New Customer Modal ---------------- */
.new-customer-form {
  font-family: "DM Sans", sans-serif;
}

.new-customer-form .header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  color: #000000;
  font-weight: bold;
  margin-bottom: 20px;
}

.header-row svg {
  cursor: pointer;
}

.new-customer-form label {
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  /* margin: 0; */
}

.new-customer-form .form-group {
  margin-bottom: 1.5rem !important;
}

.new-customer-form .form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.new-customer-form .form-control {
  font-family: "DM Sans", sans-serif;
  font-size: 1em;
  border: 1px solid #d0cece;
  border-radius: 10px;
  padding: 0.6rem !important;
  margin: 0px !important;
  color: #666666;
  background-color: #ffffff !important;
}

.new-customer-form .form-control:focus {
  box-shadow: none !important;
  border: 1px solid #d0cece !important;
}

.new-customer-form .form-control.is-invalid:focus,
.new-customer-form .form-control.is-valid:focus {
  box-shadow: none !important;
}

.phone-input-custom {
  border: 1px solid #d0cece !important;
  background-color: #ffffff !important;
  display: flex;
  align-items: center;
  padding: 0px;
  border-radius: 10px;
  margin-bottom: 0 !important;
}

.phone-input-custom .form-control {
  border-radius: 0 10px 10px 0 !important;
  margin-left: 38px !important;
}

.form-control.description-text-area {
  padding: 0.4rem 0.5rem !important;
}

.add-customer-submit-btn {
  margin-bottom: 1.5rem !important;
}

.status-switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #434ce6 !important;
  background-color: #434ce6 !important;
}

/* .filter-popup-recent::before {
  right: 60px !important;
} */

.create-bill-footer-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.create-bill-help-btn {
  border-radius: 50%;
  background-color: #ffffff;
  color: #4272c4;
}

.create-bill-help-btn:hover {
  background-color: #555555;
}

@media screen and (max-width: 576px) {
  .create-bill-help-btn {
    display: none;
  }

  .create-bill-footer-btn-container {
    padding: 0 !important;
    justify-content: space-around;
  }

  .create-bill-footer-btn-container .custom-primary-button {
    font-size: 12px;
    padding: 5px 10px;
  }

  .custom-primary-button.custom-filter-btn {
    font-size: 12px;
    padding: 5px 10px;
    margin-top: 10px;
  }

  .custom-primary-button.custom-filter-btn svg {
    font-size: 14px;
  }
}

/* ----------------- New Customer Modal ---------------- */
