/* ....COMPANY PROFILE CSS.... */

.companyProfileContainer {
  /* border: 2px solid; */
  margin-top: 52px;
  min-height: 100%;
  width: 97%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  background-color: white;
}

.comapnyName {
  font-size: 28px;
  text-align: center;
  vertical-align: middle;
  /* margin-top: 20px; */
  font-weight: 600;
}

.searchContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  /* border: 2px solid; */
}


.searchInput, .searchButton {
  padding: 8px; 
  font-size: 18px; 
  border-radius: 4px;
  border: 2px solid #ccc; 
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(181, 162, 162, 0.878);
  height: 48px; 
}

.searchInput {
  width: 400px; 
  margin-right: 3px;
  margin-top: 10px;
  border: 1px solid;
}

.searchInput:focus {
  border-color: #007BFF; 
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2); 
}

.searchButton {
  cursor: pointer;
  border: none;
  background-color: #007BFF; 
  color: white;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.searchButton:hover {
  background-color: #0056b3;
}

.searchButton:active {
  background-color: #004494; 
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); 
}

.all_data {
/* border: 1px solid; */
  width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap; /* Changed to wrap */
  height: auto;
  /* overflow-x: auto;  */
}

.company_details {
  margin: auto;
  margin-top: 25px;
  width: 100%; 
  display: flex;
  flex-wrap: wrap; /* Changed to wrap */
  justify-content: flex-start; /* Align items to start */
  gap: 10px;
}


.company_image_and_title {
  flex: 0 0 21%; /* Adjusted to control the number of items per row */
  height: 90px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px; 
  box-sizing: border-box;
  /* border: 1px solid #000; */
  border-radius: 10px;
  transition: all 0.3s ease;
  box-shadow: 0 20px 30px -20px #26394d;
}

.company_img {
  width: 60px;
  height: 40px;
}

.company_image_and_title:hover {
  background-color: #f0f0f0; 
  transform: scale(0.9); 
}

.company_image_and_title h3 {
  margin: 0;
  padding-top: 10px;
  font-size: 17px;
  font-weight: 510;
}

.highlight {
  /* border: 2px solid #ffcc00; */
  /* background-color: #fffbcc; */
}

/* create-company-container */

body {
  font-family: 'Arial', sans-serif;
  background-color: #f8f9fa;
  margin: 0;
  padding: 0;
}

/* Container Styling */
.company-container,.company-address-container,.company-user-container,
.company-setting-container,.company-header-footer-container,.company-notification-container,
.company-tax-and-setting-container,.company-branch-details-container {
  width: 90%;
  margin: 40px auto;
  padding: 20px;
  /* border: 2px solid green; */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 8px;
}

.company-container:hover,.company-address-container:hover,
.company-user-container:hover,.company-setting-container:hover
.company-header-footer-container:hover,.company-notification-container:hover,
.company-tax-and-setting-container:hover{
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Header Styling */
.company-name,.company-address-name   {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-name h5,.address-name h5 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.company-name .btn-primary {
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.company-name .btn-primary:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Form Styling */
.company-form,.company-address-form {
  margin-top: 20px;
}

.company-form div {
  margin-bottom: 20px;
}

.company-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.company-form .form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.company-form .form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Button Group Styling */
.button-group {
  display: flex;
  gap: 10px;
}

.button-group .m-r-20 {
  margin-right: 20px;
}

.button-group .btn-primary {
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.button-group .btn-primary:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/*.... company-address-container ....*/

.company-address-form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;
}

.main-form-address-cont,.main-form-user-cont {
  display: flex;
  gap: 20px;
}

.addewss-1,.user-1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.addewss-1 label,.user-1 label{
  font-size: 16px;
  font-weight: 600;
}

.addewss-1 input, .user-1 input{
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
}

.addewss-1 input:focus,.user-1 input:focus{
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.button-group {
  display: flex;
  gap: 10px;
}

.button-group .btn-primary {
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.button-group .btn-primary:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}


/* ... Company profile responsive  .... */

@media only screen and (max-width: 1200px) {
 .company_image_and_title h3{
   font-size: 15px;
  font-weight: 510;
 }
  .company_image_and_title {
    flex: 0 0 45%; 
  }
 .company_img {
  width: 40px;
  height: 30px;
}
}

@media only screen and (max-width: 650px) {
 .company_image_and_title h3{
  font-weight: 500;
 }
   .company_image_and_title {
    flex: 0 0 100%; 
  }

 .searchInput, .searchButton {
  padding: 8px; 
  font-size: 16px; 
  height: 40px; 
}

.searchInput {
  width: 300px; 
}
.all_data {
border: 1px solid;
  width: 90%;
  
}
.company_image_and_title {
 /* border: 1px solid; */
 position: relative;
 
 width: 400px;
}

}




