.popover-container {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1030;
}

.btn-fixed {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 6px !important;
  background-color: #ffffff !important;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.btn-fixed:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15) !important;
}

.draft-icon {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(90deg);
}

.draft-popover .popover {
  background-color: #f9f9f9;
  border-radius: 10px;
}

.draft-popover .popover-inner {
  background-color: #f9f9f9;
  border-radius: 10px;
  font-family: "DM Sans", sans-serif !important;
}

.draft-popover .popover-header {
  background-color: #f9f9f9;
  font-size: 16px;
  font-weight: bold;
  color: #333 !important;
  border-bottom: 1px solid #ddd !important;
  font-family: "DM Sans", sans-serif !important;
}

.draft-popover .popover-body {
  max-height: 60vh;
  overflow-y: auto;
}

.draft-card {
  border-radius: 10px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.draft-card .card-body {
  padding: 10px;
  border-radius: 10px;
  background-color: #f9f9f9;
  cursor: pointer;
  font-family: "DM Sans", sans-serif;
}

.draft-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.draft-card span {
  font-size: 12px;
  color: #666;
  font-family: "DM Sans", sans-serif;
}

.draft-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin: 0;
  font-family: "DM Sans", sans-serif;
}

.draft-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.draft-time {
  font-size: 14px;
  color: #666;
}

.action-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 5px; */
  background: #434ce6;
  color: #fff;
  cursor: pointer;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.1);
}
.custom-popover .popover {
  max-width: 600px !important;
}
