.content-dashboard-createbill {
    font-family: 'DM Sans', Arial, sans-serif;
}

.content-dashboard-createbill .title {
    font-size: 1.2rem;
    font-weight: 500;
    color: #434CE6;
}

.card.create-bill-template-card {
    border-radius: 10px !important;
    padding: 10px 20px !important;
    font-family: 'DM Sans', Arial, sans-serif;
}


.create-bill-template-card .form-control {
    font-size: 14px !important;
    margin: 0 auto !important;
}

.form-control.custom-input-control {
    font-size: 1em;
    font-family: "DM Sans", sans-serif;
    border: 1px solid #D0CECE;
    border-radius: 10px;
    padding: 0.6rem !important;
    margin: 0px !important;
    color: #666666;
    background-color: #FBFBFB !important;
}

.custom-primary-button.reset-btn {
    background-color: #3F3F3F;
}

.custom-primary-button.reset-btn,
.custom-primary-button.submit-btn {
    width: 100px;
    height: 35px;
}

.template-edit-btn {
    color: #434CE6;
    background-color: transparent !important;
    margin: 0 auto !important;
    box-shadow: none;
}

.template-edit-btn:hover {
    background-color: transparent;
    box-shadow: none;
    color: #434CE6;
}

.form-control.template-status-dropdown {
    width: 100px !important;
    font-family: 'DM Sans', sans-serif;
    border-radius: 10px;
    padding: 0.6rem;
    margin: 0 auto !important;
    font-size: 0.8rem !important;
    font-weight: 600;
    background-color: #FBFBFB !important;
    color: #666666;
    border: none;
    cursor: pointer;
}

.template-status-dropdown.template-active {
    color: #207841;
}

.template-status-dropdown.template-inactive {
    color: #CF2020;
}