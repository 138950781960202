.btn,
.navbar .navbar-nav > a.btn{
  i {
    margin-right: 3px;
  }
}
.table :not(.btn-group) .btn + .btn,
.table .btn-group + .btn-group{
  margin-left: 4px;
}
.btn + .btn,
.btn-group + .btn-group {
  margin-left: 5px;
}

.show-more-button {
  background-color: #202020 !important;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  text-transform: none;
  margin: 0;
  padding: 5px 10px;
  text-align: center;
}

.show-more-button:hover {
  background-color: #343434 !important;
}
