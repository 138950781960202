.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 20px;
}

.register-document-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 20px;
  overflow-y: scroll;
}

.register-container-document {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 20px;
  overflow-y: auto;
}

/* input-group-text */
.input-group {
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group-prepend {
  background-color: transparent;
  border: 1px solid #E3E3E3;
  color: #66615B;
  margin: 0;
  padding: 10px;
  /* border: 1px solid; */
  border-radius: 4px;
  border-right: 0 none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  /* border-left: 0px ; */
}


.input-group .input-group-prepend .input-group-text {
  padding: 10px 0 10px 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend .input-group-text {
  border-right: none;
  background-color: transparent;
  border: 1px solid #E3E3E3;
  color: #66615B;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.input-group .form-control:last-child {
  border-top-left-radius: 0;
  padding: 10px 10px 10px 0px !important;

}

.input-group-text {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.25rem;
}

.nc-icon {
  display: inline-block;
  font: normal normal normal 14px / 1 "nucleo-icons";
  font-size: inherit;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}

/* .input-group-text i{
  width: 17px;
  opacity: 0.5;
} */
.input-group,
.form-control:last-child {
  border-left: 0 none;
}

.input-group>.form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group .form-control {
  padding: 10px 10px 10px 10px !important;
}

.input-group>.form-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.form-control {
  height: initial;
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  color: #66615b;
  line-height: normal;
  font-size: 14px;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  box-shadow: none;
  display: block;
  font-weight: 400;
  background-clip: padding-box;
}

input {
  overflow: visible;
}

.register-container.form-group .form-control,
.register-container.input-group .form-control {
  padding: 10px 10px 10px 35px !important;
  height: 38.4px !important;
  text-align: left;
  font-weight: 400;
  line-height: 1.5;

}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 20px;
  /* background-color: #FFFFFF; */
}

.login-container .card {
  border-radius: 10px !important;
}

.upload-button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.card-login {
  max-width: 450px;
  width: 100%;
  margin: auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #b47f7f;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(198, 171, 171);
  background-color: #fff;
  z-index: 1000;
  /* background-color: transparent; */
}

.card-registration {
  max-width: 500px;
  width: 100%;
  margin: auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #b47f7f;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(198, 171, 171);
  background-color: #fff;
  z-index: 1000;
}

.card-registration-document {
  max-width: 900px;
  width: 100%;
  margin: auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #b47f7f;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(198, 171, 171);
  background-color: #fff;
  z-index: 1000;
}

.stepper-container {
  margin-bottom: 20px;
}

@media (max-width: 576px) {
  .card-login {
    padding: 10px;
  }
}

.registration-header {
  margin-bottom: 20px;
  text-align: center;
}

.gradient-text {
  background: linear-gradient(-45deg, #007bff, #ff55a5, #ffd700, #00dbde);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient-shift 4s ease infinite;
  margin: 0;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

@keyframes gradient-shift {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.typing-effect {
  border-right: 0.15em solid black;
  white-space: nowrap;
  overflow: hidden;
  font-size: 2rem;
  animation: typing 4s steps(40, end), blink-caret 0.75s step-end infinite;
  font-weight: bold;
  text-align: center;
}

/* Typing animation */
@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

/* Cursor blink animation */
@keyframes blink-caret {

  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: black;
  }
}


.has-label>label {
  font-size: 14px;
  color: #333;
  /* margin-bottom: 10px; */
  font-weight: bold;
}

.has-label>div>label {
  font-size: 14px;
  color: #333;
  /* margin-bottom: 10px; */
  font-weight: bold;
}

.has-label>div>a {
  font-size: 14px;
  /* color: #333; */
  /* margin-bottom: 10px; */
  font-weight: bold;
}

.has-label .PhoneInput input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.has-label .PhoneInput input:disabled {
  background-color: #e9ecef;
  color: #495057;
}


.btn-mobileInput {
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
  border-radius: 5px;
  background-image: linear-gradient(to right, #fbc658 0%, #fbc658 100%) !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  /* Prevent text selection */
  user-select: none;

  /* Smoother font rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-mobileInput:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.btn-mobileInput:active {
  transform: translateY(1px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: Arial, sans-serif;
  margin-bottom: 15px;
}

.verification-text {
  font-size: 22px;
  font-weight: bold;
  color: #333;
}

.step-indicator {
  font-size: 16px;
  color: #555;
}

.otpInput {
  width: 40px;
  height: 40px;
  margin: 5px;
  text-align: center;
  font-size: 1.2em;
}

.otpBox {
  display: flex;
  align-items: center;
  justify-content: center;
}



.uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /* margin-top: 20px; */
}

.preview {
  width: 400px;
  aspect-ratio: 16 / 9;
  border: 2px solid #ccc;
  margin-top: 10px;
}

/* Default mobile styles: images stacked vertically */
.images-grid {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.images-grid .preview {
  max-width: 90%;
  /* Adjust based on preference */
  margin-bottom: 20px;
  /* Space between images */
}

/* Desktop styles: images side by side */
@media (min-width: 768px) {

  /* Adjust breakpoint as needed */
  .images-grid {
    flex-direction: row;
    justify-content: center;
  }

  .images-grid .preview {
    max-width: 45%;
    /* Adjust based on preference */
    margin: 0 10px;
    /* Space around images */
  }
}


.document-details-page {
  padding: 20px;
  max-width: 600px;
  margin: 20px auto;
  background: #f9f9f9;
  border-radius: 8px;
  max-height: fit-content;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.detail-item {
  margin-bottom: 10px;
  padding: 10px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.detail-item h5 {
  margin-bottom: 5px;
}

.detail-item strong {
  font-weight: 600;
}

/* -------------- NEW LOGIN PAGE -------------- */

.auth-banner-title-row {
  margin-top: 20px;
}

.login-heading,
.login-para {
  font-family: 'DM Sans', 'Helvetica Neue', Arial, sans-serif;
  font-weight: 600;
  margin-bottom: 15px;
}

.login-para {
  font-size: 16px;
}

.auth-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.company-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
  padding-top: 10px;
}

.company-title-container img {
  width: 200px;
  object-fit: contain;
}

.login-card-container {
  border-radius: 10px !important;
}

.login-form-container {
  min-height: 400px;
  padding: 20px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.otp-form-container .form-group,
.login-form-container .form-group {
  margin-bottom: 0 !important;
}

.login-header {
  font-family: 'Poppins', 'Helvetica Neue', Arial, sans-serif;
  background-color: #202020;
  margin-bottom: 0 !important;
}

.login-header-title {
  font-size: 16px;
  font-weight: 500;
}

.login-header-title a {
  font-size: 14px;
  color: #A6A9F7;
  text-decoration: underline;
}

.social-media-icons {
  color: #FFFFFF !important;
}

.social-media-icons .phone-icon:hover {
  color: #434CE6;
}

.social-media-icons .email-icon:hover {
  color: #ff4949;
}

.social-media-icons .whatsapp-icon:hover {
  color: #25d366;
}

.social-media-icons .linkedin-icon:hover {
  color: #0077B5;
}


.social-media-icons svg {
  font-size: 20px;
}

.react-tel-input-custom,
.input-group-custom {
  border: 1px solid #F1F1F1 !important;
  background-color: #F1F1F1 !important;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 0 !important;
}

.input-group-custom .form-control {
  padding: 10px !important;
  padding-right: 0 !important;
}

.input-group-custom .form-control.is-invalid:focus,
.input-group-custom .form-control.is-valid:focus {
  border: none !important;
  box-shadow: none !important;
}

.react-tel-input-custom input,
.input-group-custom input {
  font-family: "DM Sans", sans-serif;
  font-weight: 600 !important;
  font-size: 16px;
  border-radius: 0 !important;
  background-color: transparent;
  border: none;
}

.flag-search-btn-custom .selected-flag:focus,
.flag-search-btn-custom .selected-flag:hover,
.input-group-custom input:focus {
  background-color: transparent !important;
  border: none !important;
}

.input-group-append {
  border-radius: 10px;
}

.react-tel-input-custom .form-control {
  margin-left: 40px !important;
}

.react-tel-input-custom .form-control,
.input-group-text-custom {
  background-color: transparent !important;
  border: 1px solid #F1F1F1 !important;
  padding: 10px !important;
}

.input-group-text-custom svg {
  font-size: 18px;
}

.flag-search-btn-custom,
.input-custom {
  background-color: #F1F1F1 !important;
  border: none !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  padding: 10px;
  flex: 1;
}

.forgot-password-container,
.sign-up-text {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  margin: 5px 0;
  color: #747474
}

.forgot-password-container a {
  font-weight: 500 !important;
  color: #747474 !important;
}

.sign-up-text a {
  color: #000000;
}

.custom-login-btn {
  height: auto;
  line-height: normal;
  background-color: #5932EA !important;
  color: #FFFFFF;
  font-family: 'DM Sans', Arial, sans-serif;
  font-size: 16px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  outline: none;
  user-select: none;
  text-transform: none !important;
  white-space: normal;
  padding: 10px;
}

.secondary-login-btn {
  border: 1px solid #434CE6;
  color: #434CE6;
  background-color: #FFFFFF !important;
}

.custom-login-btn:hover,
.secondary-login-btn:hover {
  background-color: #1c1c1c !important;
  color: #FFFFFF;
  border: none;
}

.login-btn-link {
  font-family: 'DM Sans', Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF !important;
  padding: 4px 10px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.login-btn-link:hover {
  text-decoration: none;
  scale: 1.05;
}

.auth-banner-container {
  background: rgb(73, 82, 236);
  background: linear-gradient(0deg, rgba(73, 82, 236, 1) 0%, rgba(242, 239, 255, 1) 100%);
}

.auth-banner {
  height: 400px;
  background-image: url('https://erpcontent.azureedge.net/erpcdncontainer/erp-dashboard-img.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-x: 20px;
  background-position-y: 20px;
}

.form-group-control-container {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
}

.steps p {
  margin-bottom: 5px;
  font-size: 12px;
}

.step-count {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  color: #FFFFFF;
  background-color: #D9D9D9;
}

.line {
  width: 80px;
  height: 8px;
  border-radius: 10px;
  background-color: #D9D9D9;
}

.active-step {
  color: #FFFFFF;
  background-color: #434CE6;
}

.sign-up-with-docs-container {
  width: 100%;
  min-height: 400px;
  padding: 20px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.sign-up-with-docs-container .header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.sign-up-with-docs-container .header-title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.custom-select {
  border: 1px solid #F1F1F1 !important;
  background-color: #F1F1F1 !important;
  padding: 10px !important;
  border-radius: 10px !important;
}

@media screen and (max-width: 768px) {
  .custom-login-btn {
    font-size: 12px;
  }
}

/* -------------- NEW LOGIN PAGE -------------- */