/* body {
  font-family: sans-serif;
} */

.stepper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  z-index: 2;
}

.step-name {
  font-size: 14px;
}

.active .step-number {

  color: white;
}

.complete .step-number {
  background-color: #28a745;
  color: #fff;
}

.progress-bar {
  position: absolute;
  top: 25%;
  left: 0;
  height: 4px;
  background-color: #b4cdcd !important;
}

.progress {
  height: 100%;
  background-color: #28a745;
  transition: 0.2s ease;
}


.step-icon {
  background-color: transparent;
}

.step-icon-active {
  animation: iconAnimation 2s infinite;
}


@keyframes iconAnimation {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.1); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
}
