* {
  padding: 0px;
  margin: 0px;
}

.container-box-main {
  height: 100vh;
  width: 100vw;
  max-width: 4000px;
  /* overflow: hidden; */
  position: relative;
  background-image: url("../../assets/img/bg/world-map-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.background-boxes {
  position: absolute;
  left: 75%;
  top: 50%;
  width: 500%;
  height: 250%;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  transform: translate(-50%, -50%) skewX(-48deg) skewY(14deg) scale(0.675)
    rotate(0deg);
}

.box {
  width: 64px;
  height: 32px;
  border: 1px solid #334155;
}
