.icon-bg {
  background: linear-gradient(180deg, #e93b77 0%, #da1f63 100%);
  border-radius: 8px;
  width: 55px;
  height: 62px;
  margin-left: auto;
  margin-right: auto;
}

.icon-ic {
  font-size: 25px;
  line-height: 62px;
  vertical-align: middle;
}

.line-divider {
  height: 1px;
  background: #f0f2f5;
  border: none;
}

.card-title-box {
  min-height: 135px;
}

.patient-text {
  font-size: 20px;
  color: #4fcd6b;
}

.invoices-box {
  min-height: 302px;
}

.recent-billing-box {
  min-height: 450px;
}

.pending-billing-box {
  min-height: 450px;
}

.view-all-btn {
  border: 1px solid #e91f63;
  border-radius: 8px;
  color: #e91f63;
}

.invoice-date {
  font-size: 14px;
  color: #344767;
}

.invoice-number {
  font-size: 12px;
  color: #7b809a;
}

.invoice-amount {
  font-size: 14px;
  color: #7b809a;
}

.pdf-text {
  font-size: 14px;
  color: #344767;
}

.invoice-title {
  font-size: 16px;
  color: #344767;
}

.price-amount {
  font-size: 20px;
  color: #344767;
}

.card-title {
  font-size: 16px;
  /* color: #344767; */
}

.add-new-card-btn {
  background: linear-gradient(180deg, #3e3d45 0%, #202020 100%);
  /* drop-shadow-black */
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.input-box {
  height: 50px;
}

.bill-lable {
  font-size: 12px;
  color: #7b809a;
}

.bill-value {
  font-size: 12px;
  color: #344767;
}

.bill-title {
  font-size: 14px;
  color: #344767;
}

.bill-box {
  background: #f8f9fa;
  border-radius: 8px;
}

.bill-date {
  font-size: 14px;
  color: #7b809a;
}

.newest-text {
  font-size: 12px;
  color: #7b809a;
}

.patient-name {
  font-size: 14px;
  color: #344767;
}

.patient-date {
  font-size: 12px;
  color: #7b809a;
}

.minus-amount {
  font-size: 14px;
  color: #f44334;
}

.plus-amount {
  font-size: 14px;
  color: #4caf50;
}

.down-circle-icon {
  color: #f44334;
  font-size: 27px;
}

.up-circle-icon {
  color: #4caf50;
  font-size: 27px;
}

/* Dashboard Widget Styling */
/* General Card Styles */
.dashboard-card {
  width: 100%;
  border-radius: 10px !important;
  background-color: #f4f7fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  margin-bottom: 1rem;
  height: 150px;
  color: #454545 !important;
  font-family: 'Poppins', sans-serif;
}

.dashboard-card:hover {
  background-image: url('~assets/img/bg/dashboard-active-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #4951ecd1;
  cursor: pointer;
  color: #FAFAFA !important;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}

.dashboard-card:hover span,
.dashboard-card:hover .net-profit-card-value,
.dashboard-card:hover .net-expense-card-value,
.dashboard-card:hover .dashboard-icon,
.dashboard-card:hover .net-profit-card-value-icon,
.dashboard-card:hover .net-expense-card-value-icon {
  color: #FAFAFA !important;
}

.dashboard-card:hover .dashboard-icon-conainer {
  background-color: #8c91eecb !important;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-icon-conainer {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-icon {
  color: #4951ecd1;
  width: 20px;
  height: 20px;
}

.card-body {
  padding: 10px;
}

.card-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: left;
}

.card-value {
  font-size: 20px;
  font-weight: 600;
  text-align: left;
}

.card-value>span {
  font-size: 30px;
  font-weight: 600;
}

.net-profit-card-value span,
.net-profit-card-value-icon {
  color: #00AA07;
}

.net-expense-card-value span,
.net-expense-card-value-icon {
  color: #EF0404;
}

.dashboard-card-bg-style {
  background-image: url('~assets/img/bg/dashboard-card-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #FAFAFA;
}

.customer-card-style {
  background-image: linear-gradient(to right, #69b3f8, #1e81b0) !important;
}

.sales-card-style {
  background-image: linear-gradient(to right, #3cb4c7, #208b93) !important;
}

.expense-card-style {
  background-image: linear-gradient(to right, #ff6b6b, #c74545) !important;
}

.profit-card-style {
  background-image: linear-gradient(to right, #93c47d, #548f44) !important;
}

/* Individual Card Styles with Gradient Backgrounds */
/* .customer-card-style {
  background-image: linear-gradient(to right, #7fdbda, #1a8fab) !important;
}

.sales-card-style {
  background-image: linear-gradient(to right, #a8e0af, #57a773) !important;
}

.expense-card-style {
  background-image: linear-gradient(to right, #fab4b4, #d04a6a) !important;
}

.profit-card-style {
  background-image: linear-gradient(to right, #eed6c4, #a1887f) !important;
} */

/* 
.dashboard-dropdown .p-dropdown {
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #dfe1e5;
}

.dashboard-dropdown .p-dropdown-label {
  font-weight: 500;
  color: #333;
} */

/* Dropdown Styling */
.dashboard-dropdown .p-dropdown {
  border-radius: 5px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid #dfe1e5 !important;
}

.dashboard-dropdown .p-dropdown-label {
  font-weight: 500 !important;
  color: #333 !important;
}

/* Styling for the dropdown panel */
.dashboard-dropdown .p-dropdown-panel {
  border-radius: 5px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !;
  max-height: 250px !important;
  /* Adjust based on your needs */
  overflow-y: auto !important;
  /* Enables scrollbar */
}

/* Custom scrollbar styles */
.dashboard-dropdown .p-dropdown-panel::-webkit-scrollbar {
  width: 8px !important;
}

.dashboard-dropdown .p-dropdown-panel::-webkit-scrollbar-track {
  background-color: #f1f1f1 !important;
  border-radius: 10px !important;
}

.dashboard-dropdown .p-dropdown-panel::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  border-radius: 10px !important;
}

.dashboard-dropdown .p-dropdown-panel::-webkit-scrollbar-thumb:hover {
  background-color: #555 !important;
}

/* .dashboard-widget-wrapper {
  gap: 15px 0;
} */

.pie-chart-label {
  color: #202020;
  font-weight: 600;
}

.content-dashboard {
  margin-top: 60px !important;
}

.content-dashboard .card {
  border-radius: 10px !important;
}

.content-dashboard .filter-wrapper {
  margin: 0 0 5px;
}

.actions-btn {
  width: 35px;
  height: 35px;
  background-color: #F8F8FF;
  box-shadow: none;
  border-radius: 10px;
  text-align: center;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions-btn .table-action-icon {
  font-size: 16px;
  color: #434CE6;
}

.actions-btn:hover {
  background-color: #f8f8ffb4;
}

/*-------- New ERP Design ---------*/

.table-title-link {
  color: #202020 !important;
}

.btn-rotate svg {
  -webkit-transition: all 370ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -moz-transition: all 370ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -o-transition: all 370ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -ms-transition: all 370ms cubic-bezier(0.34, 1.61, 0.7, 1);
  transition: all 370ms cubic-bezier(0.34, 1.61, 0.7, 1);
  position: relative;
  display: inline-block;
}

.btn-rotate:hover svg,
.btn-rotate:focus {
  svg {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(53deg);
    -ms-transform: rotate(53deg);
    transform: rotate(53deg);
  }
}

@media screen and (max-width: 991px) {
  .logout-button {
    margin: 0 10px !important;
  }
}

@media screen and (max-width: 768px) {
  .content-dashboard .content {
    padding: 0px !important;
  }
  
  .dashboard-title-row {
    /* flex-direction: column; */
    /* gap: 10px; */
  }

  .btn-rotate {
    display: none;
  }

  .card-title {
    font-size: 14px !important;
  }

  .card-value {
    font-size: 16px !important;
  }

  .card-value>span {
    font-size: 24px !important;
  }

  .dashboard-icon-conainer {
    width: 30px !important;
    height: 30px !important;
  }

  .dashboard-icon {
    width: 16px !important;
    height: 16px !important;
  }

  .sitemap-btn {
    display: none;
  }
}

/*-------- New ERP Design ---------*/