.filter-container {
  position: relative;
  display: inline-block;
  font-family: 'DM Sans', Arial, sans-serif;
}
.filter-button {
  padding: 5px 12px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 12px;
  gap: 5px;
  transition: background-color 0.3s ease;
  margin: 0;
}
.filter-button:hover {
  background-color: #f2f2f2;
}
.filter-icon {
  margin-left: 5px;
  transition: transform 0.3s ease;
}
.filter-popup {
  position: absolute;
  top: 100%;
  left: -216px;
  background-color: #fff;
  padding: 20px;
  z-index: 1000;
  width: 340px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}

.filter-container .filter-popup {
  opacity: 1;
  visibility: visible;
  transform: translateY(10px);
}

/* Arrow style */
.filter-popup::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 300px;
  transform: rotate(45deg);
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.1);
  z-index: -1;
}

.time-range-popup {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 300px;
}
.time-range-options label {
  display: block;
  margin-bottom: 5px;
}
.custom-time-range {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.popup-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.time-range-popup {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  z-index: 1000;
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  max-width: 500px;
  margin: auto;
}
.time-range-options {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
}
.time-option {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.time-option input[type="radio"] {
  margin-right: 7px;
  cursor: pointer;
}
.label-text {
  display: inline;
  cursor: pointer;
  font-size: 12px !important;
  color: #333;
  text-align: center;
}
.custom-option {
  grid-column: 1 / -1;
}

.time-option input[type="radio"] {
  accent-color: #007bff;
}
.time-option .label-text {
  font-size: 14px;
  color: #333;
}
.date-picker-nav-button {
  border: none;
  background: transparent;
  cursor: pointer;
  /* font-size: 16px;
  margin: 0 10px; */
}

.date-picker-nav-button:disabled {
  opacity: 0.5;
  cursor: default;
}

.date-picker-select {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  margin: 0 5px;
}

.react-datepicker {
  font-size: 12px !important;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
  background-color: white;
  border-bottom: none;
}

.react-datepicker__current-month {
  font-weight: bold;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  background-color: #007bff;
  color: white;
}

.react-datepicker__header {
  background-color: rgb(255, 255, 255) !important;
  /* color: #51cbce !important; */
}
.react-datepicker-time__header{
  /* color: #51cbce !important; */
}

.react-datepicker__day-name {
  /* color: #51cbce !important; */
}
/* Styling for the DatePicker navigation buttons */
.date-picker-nav-button {
  margin: 0;
  background: #434CE6;
  border: none;
  color: #FFFFFF;
  font-weight: bold;
  transition: background-color 0.2s ease, border-radius 0.2s ease;
  cursor: pointer;
}

.date-picker-nav-button:hover {
  background-color: rgba(67, 76, 230, 0.8);
  border-radius: 50%;
}

.date-picker-nav-button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.react-datepicker__time-list {
  scrollbar-width: thin;
  scrollbar-color: #6cb3ff #f0f0f0;
}

.react-datepicker__time-list::-webkit-scrollbar {
  width: 6px;
  height: 20px;
}

.react-datepicker__time-list::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.react-datepicker__time-list::-webkit-scrollbar-thumb {
  background-color: rgb(106, 101, 101);
  border-radius: 4px;
}

.react-datepicker__time-list::-webkit-scrollbar-thumb:hover {
  background: #9c9191;
}
.custom-date-input {
  border: 1px solid #D0CECE;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  color: #323232 !important;
  gap: 10px; 
  width: 200px;
  margin: 0;
  /* padding: 6px 10px 5px 25px; */
}

.custom-date-input:hover{
  background-color: #f2f2f2;
  color: #323232;
}

.custom-date-input:focus {
  outline: none;
  border-color: #434CE6;
}

.react-datepicker__day--selected {
  background-color: #434CE6 !important;
  border-radius: 50% !important;
}

/* Additional styling as needed */

@media screen and (max-width: 768px) {
  .filter-button {
    font-size: 10px;
  }
}