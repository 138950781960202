.landing-page {
    background-color: rgb(255, 255, 255);
    color: black;
    height: 100%;
    width: 100%;
    font-family: "Figtree", serif;
}

.blink {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

/* ------------ Navbar ------------ */

.navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 80px;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -2px rgba(0, 0, 0, 0.1);
}

.nav-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-logo-container img {
    width: 160px;
    height: auto;
}

.nav-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.nav-btn {
    height: auto;
    text-align: center;
    padding: 0px 16px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: normal;
    text-transform: none;
    margin-top: 0;
    transition: all 0.3s ease-in;
    border: none;
    border-radius: 5px;
    font-family: "Figtree", serif;
}

.nav-demo-btn {
    color: #ffffff;
    background-color: #434ce6;
}

.nav-login-btn {
    color: #ffffff;
    background-color: #000000;
}

.nav-login-btn:hover {
    background-color: #000000;
}

.nav-demo-btn:hover {
    background-color: #434ce6;
}
/* ------------ Navbar ------------ */

.landing-page-container {
    width: 100%;
    padding: 80px;
}

.landing-page-title {
    font-size: 2.8rem;
    font-weight: 600;
    text-align: left;
    margin-bottom: 30px !important;
    background: radial-gradient(#434ce6, #5b969e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* ------------ Hero ------------ */
.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    background: linear-gradient(
        to bottom,
        #ffffff80,
        #ffffff4d,
        #aaaaff80,
        #111156
    );
    color: black;
    padding: 0;
    gap: 40px;
    border-radius: 0 0 50px 50px;
}

.hero-section h1 {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
}

.hero-section span {
    background: linear-gradient(to right, #434ce6, #1f1b2e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-section p {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin: 10px 0;
    color: #555555;
}

.login-btns-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.login-btns-container button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    text-transform: none;
    border-radius: 5px;
    font-family: "Figtree", serif;
    margin: 0;
}

.erp-demo-btn {
    display: none;
    background: linear-gradient(45deg, #434ce6, #252a80);
}

.pharmacy-btn {
    background: linear-gradient(45deg, #434ce6, #252a80);
}

.admin-btn {
    background: linear-gradient(45deg, #008b6c, #0e5142);
}

.doctor-btn {
    background: linear-gradient(45deg, #ff5c00, #993700);
}

.hero-dashboard-img {
    width: 100%;
    height: auto;
    border-radius: 50px 50px 0 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 40px;
}

.hero-dashboard-img img {
    width: 80%;
    height: auto;
    border: 4px solid black;
    border-bottom: 0;
    border-radius: 50px 50px 0 0;
}

/* ------------ Hero ------------ */

/* ------------ Features ------------ */
.features-section {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: white;
    margin-top: 80px;
}

.pricing-section h1,
.features-section h1 {
    font-size: 2.6rem;
    font-weight: bold;
    color: #16184c;
    margin: 40px 0;
}

.feature-card {
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: #e8edf6;
    border-radius: 20px;
}

.feature-card .card-body {
    padding: 0;
}

.feature-card ul {
    list-style-position: inside;
}

.feature-details {
    padding: 20px 40px;
}

.feature-details h3 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #16184c;
    margin: 0;
    margin-bottom: 10px;
}

.feature-details h3 span {
    color: #434ce6;
}

.feature-details p {
    font-size: 1rem;
    font-weight: 500;
    color: #737373;
    margin: 0;
}

.feature-details .feature-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    color: #737373;
    padding: 0;
}

.multi-device-feature-list {
    grid-template-columns: repeat(1, 1fr) !important;
}

.invoice-management-img {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.invoice-management-img img {
    width: 70%;
    aspect-ratio: 0.82;
    object-fit: contain;
    border-end-end-radius: 20px;
}

.schedule-notifications-card .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.schedule-notifications-card .card-body::before {
    content: "";
    position: absolute;
    top: 0;
    z-index: 2;
    height: 200px;
    width: 100%;
    background: linear-gradient(
        to bottom,
        #e8edf6 50%,
        rgba(255, 255, 255, 0) 100%
    );
    border-radius: 20px 20px 0 0;
}

.analytics-img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.analytics-img img {
    width: 100%;
    aspect-ratio: 1.25;
    object-fit: contain;
}

.sales-img-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 40px;
}

.sales-img-grid div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sales-img-grid img {
    width: 150px;
    aspect-ratio: 1/1;
    object-fit: contain;
}

.inventory-management-img {
    overflow: hidden;
    margin-top: 40px;
}

.inventory-management-img img {
    width: 100%;
    aspect-ratio: 1.43;
    object-fit: contain;
    transform: translateX(5rem);
}

.multi-device-support-img {
    width: 100%;
}

.multi-device-support-img img {
    width: 100%;
    aspect-ratio: 1.28;
    object-fit: cover;
}

.invoice-management-card {
    background-image: url("https://erpcontent.azureedge.net/erpcdncontainer/windows-app-bg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
}

.invoice-management-card h3 {
    margin-bottom: 10px;
}

.invoice-management-card h3,
.invoice-management-card li {
    color: white !important;
}
/* ------------ Features ------------ */

/* ------------ Pricing ------------ */
.pricing-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: white;
    padding: 40px 0;
}

.pricing-section .price-card {
    position: relative;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 20px;
    padding: 40px 40px 20px;
    background-color: #e9edf6;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    transition: all 300ms ease-in-out;
}

.price-card .plan-name {
    position: absolute;
    top: 20px;
    right: 40px;
    color: #434ce6;
    font-size: 1.2rem;
    font-weight: 600;
}

.price-card .price-text {
    font-size: 1.5rem;
    font-weight: 600;
    color: #000000;
}

.price-card .price-text span {
    font-size: 2.5rem;
    font-weight: 600;
    color: #1a0e4c;
    /* background: linear-gradient(-45deg, #434ce6, #000000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    font-family: "Poppins", sans-serif;
}

.features-included {
    display: flex;
    flex-direction: column;
    list-style: none;
    font-size: 1.2rem;
    color: #515151;
    margin: 0;
}

.features-included p {
    margin: 0;
}

.choose-plan-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.choose-plan-btn button {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 25px 5px 25px 25px;
    background-color: #434ce6;
    border-radius: 50px;
    font-family: "Figtree", serif;
}

.choose-plan-btn p {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
}

.choose-plan-btn span {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background-color: #ffffff;
    color: #434ce6;
    font-size: 1rem;
}

.pricing-section .price-card:hover {
    background-image: url("https://erpcontent.azureedge.net/erpcdncontainer/windows-app-bg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    transform: scale(1.05);
    cursor: pointer;
}

.pricing-section .price-card:hover .price-text span,
.pricing-section .price-card:hover .price-text,
.pricing-section .price-card:hover .features-included {
    color: white;
}

.pricing-section .price-card:hover .plan-name {
    color: #aeb3ff;
}

.pricing-section .price-card:hover .choose-plan-btn button {
    background-color: #ffffff;
}

.pricing-section .price-card:hover .choose-plan-btn p {
    color: #434ce6;
}

.pricing-section .price-card:hover .choose-plan-btn span {
    background-color: #434ce6;
    color: #ffffff;
}
/* ------------ Pricing ------------ */

/* ------------ Other Features (DO NOT REMOVE THIS SECTION AS IT IS BEING USED IN OTHER PAGES) ------------ */
.other-features-wrapper {
    padding: 0;
}

.other-features-container {
    background-color: #f4f5ff;
    color: #555555;
    width: 100%;
    border-radius: 10px;
    padding: 20px;
}

.other-features-title {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #434ce6;
    margin-bottom: 20px;
}

.other-features-title h4 {
    margin: 0;
}

.feature-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 15px;
    margin: 0;
    padding: 0 30px;
    font-size: 16px;
}

.feature-item {
    padding: 5px;
    font-weight: 600;
}

/* ------------ Other Features ------------ */

/* ------------ Footer ------------ */

.footer-container {
    background-color: #000000;
    color: #ffffff;
    padding: 50px 80px;
}

.footer-container h4 {
    font-weight: 600;
    margin: 0 0 20px 0;
}

.footer-container p {
    font-size: 1rem;
    color: #ffffff;
    margin: 0;
}

.first-col {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}

.first-col img {
    width: 150px;
}

.quick-links {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 1rem;
    color: #ffffff;
    text-decoration: none;
}

.quick-links p {
    width: auto;
    inline-size: fit-content;
}

.quick-links p:hover {
    text-decoration: none;
    color: #ffffff80;
    cursor: pointer;
}

.media-links {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
}

.media-links a {
    width: 40px;
    height: 40px;
    font-size: 1rem;
    border-radius: 50%;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #ffffff;
}

.media-links a:hover {
    color: #ffffff80;
}

.contact-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #ffffff;
    font-size: 1rem;
    margin: 0;
}

.contact-details div {
    display: flex;
    align-items: center;
    gap: 10px;
}
/* ------------ Footer ------------ */

@media (max-width: 992px) {
    .navbar-container {
        padding: 10px 30px;
    }

    .landing-page-container {
        padding: 50px 30px;
    }
}

@media (max-width: 768px) {
    .landing-page-container {
        padding: 30px 20px;
    }

    .navbar-container {
        justify-content: space-between;
        padding: 10px 20px;
    }

    .hero-section {
        padding: 30px 0px 0;
        border-radius: 0 0 10px 10px;
    }

    .hero-section h1 {
        font-size: 2rem;
    }

    .hero-section h1 br {
        display: none;
    }

    .hero-section p {
        font-size: 0.8rem;
    }

    .hero-section .login-btns-container {
        flex-wrap: wrap;
        gap: 10px;
    }

    .hero-dashboard-img {
        margin-top: 0px;
    }

    .hero-section .hero-dashboard-img img {
        width: 90%;
        border-radius: 10px 10px 0 0;
    }

    .footer-container {
        padding: 20px;
    }

    .features-section {
        margin-top: 10px;
    }

    .nav-demo-btn {
        display: none;
    }

    .erp-demo-btn {
        display: block;
    }

    .choose-plan-btn button {
        width: 100%;
        font-size: 0.8rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .landing-page-container {
        padding: 50px 30px;
    }

    .navbar-container {
        justify-content: space-between;
        padding: 10px 30px;
    }

    .feature-details {
        padding: 15px;
    }

    .feature-details h3 {
        font-size: 1.5rem;
    }

    .feature-details p {
        font-size: 1rem;
    }

    .feature-details .feature-list {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 0px;
    }

    .sales-img-grid {
        margin-top: 0px;
    }

    .sales-img-grid img {
        width: 100px;
        aspect-ratio: 1/1;
        object-fit: contain;
    }

    .pricing-section .price-card {
        padding: 20px 15px;
    }

    .pricing-section .price-card .price-text {
        margin-top: 30px;
        font-size: 1.5rem;
    }

    .pricing-section .price-card .price-text span {
        font-size: 2rem;
    }

    .pricing-section .price-card .features-included {
        font-size: 1rem;
    }

    .choose-plan-btn button {
        width: 100%;
    }

    .login-btns-container .erp-demo-btn {
        display: none;
    }
}

@media (min-width: 1920px) and (max-width: 2560px) {
    .hero-left-section h1,
    .landing-page-title {
        font-size: 4rem;
    }

    .hero-left-section p {
        font-size: 1.5rem;
    }

    .erp-demo-btn {
        display: none !important;
    }
}

.consent-input-container {
    transform: translateX(20px);
}

.consent-input-container label {
    font-size: 12px !important;
    font-family: "DM Sans", sans-serif !important;
}
