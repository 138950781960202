/* .primary-blue-color {
    color: #1B5BBA;
}

.white-color {
    color: #fff !important;
}

.light-grey-color {
    background: #F0F2F5 !important;
}

.text-center {
    text-align: center;
}

.m-t-20px {
    margin-top: 20px;
}

.m-t-15px {
    margin-top: 15px;
}

.m-t-35px {
    margin-top: 35px;
}

.m-t-25px {
    margin-top: 25px;
}

.m-b-20px {
    margin-bottom: 20px;
}

.m-t-50px {
    margin-top: 50px;
}

.m-l-25px {
    margin-left: 25px;
}

.m-l-15px {
    margin-left: 15px;
}

.p-20px {
    padding: 20px;
}

.p-50px {
    padding: 50px;
}

.p-b-20px {
    padding-bottom: 20px;
}

.p-t-15px {
    padding-top: 15px;
}

.m-r-20px {
    margin-right: 20px;
}

.p-0 {
    padding: 0;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.delete {
    color: #F44334;
    font-size: 12px;
}

.edit {
    color: #344767;
    font-size: 12px;
}

.d-none {
    display: none;
} */

/*
* font family css
*/

/* @font-face {
    font-family: 'Roboto Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto Regular'), url('../fonts/roboto/Roboto-Regular.ttf') format('truetype');
}

.roboto-regular {
    font-family: "Roboto Regular";
}

@font-face {
    font-family: 'Roboto Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto Bold'), url('../fonts/roboto/Roboto-Bold.ttf') format('truetype');
}

.roboto-bold {
    font-family: "Roboto Bold";
}

@font-face {
    font-family: 'Montserrat ExtraBold';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat ExtraBold'), url('../fonts/montserrat/Montserrat-ExtraBold.ttf') format('truetype');
}

.montserrat-extraBold {
    font-family: "Montserrat ExtraBold";
}

@font-face {
    font-family: 'Montserrat Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat Medium'), url('../fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
}

.montserrat-medium {
    font-family: "Montserrat Medium";
} */

.custom-card {
    background: #fff;
    padding: 20px;
    box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.01);
    position: relative;
    border-radius: 4px;
}

.grid-buttons {
    font-size: 0.5em !important;
}

.forgot-pass .nav-link {
    padding-left: 2px;
}

.card label {
    font-weight: bold;
}

.dashboard-tiles .tiles-subheader {
    font-size: 16px;
    margin-bottom: 6px;
}

.dashboard-tiles .tiles-footer {
    color: #51bcda;
}

.dashboard-tiles .profit {
    color: #6bd098;
    font-weight: bold;
}

.card {
    border-radius: 4px;
}

.sales-bg {
    border: 1px solid #51cbce !important;
    background: #51cbce30;
}

.purchases-bg {
    border: 1px solid #9368e9;
    background: #9368e930;
}

.text-purchases-bg {
    color: #9368e9;
}

.button-bg {
    background: #36a2eb;
}

.parent-element .button-bg {
    background: #36a2eb !important;
}

.button-bg:focus,
.button-bg:active {
    background: #36a2eb;
}

.button-text-cust {
    color: #36a2eb;
}

.revenue-bg {
    border: 1px solid #6bd098 !important;
    background: #6bd09830;
}

.profit-bg {
    border: 1px solid #ff6384;
    background: #ff638433;
}

.text-profit-bg {
    color: #ff6384;
}

.alerts-bg {
    border: 1px solid #fbc658 !important;
    background: #fbc65830;
}

.dashboard-inventory-tile .p-datatable .p-datatable-thead > tr > th {
    font-weight: 500;
}

.cloud-file-drop {
    font-size: 50px;
    color: #4c95eb;
}

.file-drop-title {
    font-size: 25px;
    color: #0f0f0f;
}

.file-drop-desc {
    color: #676767;
}

.title-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin: 0 0 10px;
}

.title-div h5 {
    margin: 0;
}

.top-list-tab ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 24px;
    list-style: none;
    column-gap: 40px;
    padding: 0;
    margin: 10px 0 30px;
}

.top-list-tab ul li::before {
    content: ">";
    position: absolute;
    right: -25px;
}

.top-list-tab ul li {
    position: relative;
}

.top-list-tab ul li:last-child::before {
    display: none;
}

.top-list-tab ul li span.number {
    width: 40px;
    height: 40px;
    display: inline-flex;
    flex-wrap: wrap;
    background: #fafafa;
    text-align: center;
    border-radius: 100%;
    justify-content: center;
    font-size: 21px;
    line-height: 40px;
    margin-right: 10px;
}

.top-list-tab ul li.active span.number {
    background: #00d800;
    color: #fff;
}

.html2canvas-container {
    max-width: 3000px;
    max-height: 3000px;
}

.dark-header-title {
    color: #252422 !important;
}

.form-check {
    margin-bottom: 0 !important;
}

.tooltip .arrow::before {
    border-bottom-color: #28a745 !important;
}

.DemoPickerWrapper {
    padding: 0 12px;
    font-family: "Source Code Pro", monospace;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    background: white;
    margin: 24px 0 48px;
    width: 100%;
}

.DemoPicker {
    font-size: 18px;
    border-radius: 3px;
    background-color: white;
    height: 48px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    border: 0;
    width: 100%;
    color: #6772e5;
    outline: none;
    background: transparent;

    -webkit-appearance: none;
}

.DemoWrapper {
    margin: 0 auto;
    max-width: 500px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.Demo {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 40%;
}

label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
}

button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
}

button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.StripeElement {
    display: block;
    margin: 0 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
        rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}

input::placeholder {
    color: #aab7c4;
}

input:focus,
.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
        rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
    padding: 0;
}

.StripeElement.PaymentRequestButton {
    height: 40px;
}

.upload-form_display {
    width: 50%;
    align-items: center;
}

.switch {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 26px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ca2222;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #2ab934;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(46px);
    -ms-transform: translateX(46px);
    transform: translateX(46px);
}

/*------ ADDED CSS ---------*/
.on {
    display: none;
}

.on,
.off {
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 13px;
}

input:checked + .slider .on {
    display: block;
}

input:checked + .slider .off {
    display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.go2392553372,
span {
    align-self: center;
    color: #495057;
}

.react-form-wizard .wizard-card-footer {
    display: flex;
    justify-content: end;
}

.button_strat,
.button_strat_get:hover {
    background: var(--primary);
    border: 1px solid var(--primary);
    color: #fff;
    border-radius: 30px;
    padding: 12px 23px;
    text-align: center;
    display: block;
    text-decoration: none;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.button_strat_get {
    background: #fff;
    border: 1px solid var(--primary);
    color: var(--primary);
    border-radius: 30px;
    padding: 12px 23px;
    text-align: center;
    display: block;
    text-decoration: none;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.cio-plan-card__details ul {
    padding-left: 16px;
    list-style: disc;
}

.cio-plan-card__details ul li {
    padding-bottom: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: #4b4b60;
}

.cio-plan-card__details ul li a {
    color: var(--primary);
    font-weight: 600;
    text-decoration: none;
}

.item_box {
    width: 85%;
    background-color: #fff;
    color: #4b4b60;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.08);
    padding: 18px 11px 5px;
    border: 2px solid #a1a2b2;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.item_box .item_inner_box {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.item_box .item_inner_box .main_tt {
    font-size: 18px;
    letter-spacing: 0.54px;
    font-weight: 600;
    color: var(--primary);
    text-align: center;
}

.item_box .item_inner_box .plan-card__pricing .model__value {
    font-size: 35px;
    line-height: 30px;
    color: var(--primary);
    font-weight: 700;
}

.cio-plan-card__details ul {
    padding-left: 16px;
    list-style: disc;
}

.cio-plan-card__details ul li {
    padding-bottom: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: #4b4b60;
}

.item_box .item_inner_box .plan-card__pricing {
    text-align: center;
    font-size: 11px;
    line-height: 18px;
}

.btn.button_strat_get.disabled {
    opacity: 1;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
}

.text-limit-70 {
    max-width: 70ch;
    /* Limit to 70 characters */
    overflow: hidden;
    /* Hide the overflow */
    white-space: nowrap;
    /* Prevent wrapping */
    text-overflow: ellipsis;
    /* Display ellipsis (...) for overflow */
}

.blink_text {
    animation: 2s blinker linear infinite;
    -webkit-animation: 2s blinker linear infinite;
    -moz-animation: 2s blinker linear infinite;
}

@-moz-keyframes blinker {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes blinker {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes blinker {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.input-group,
.form-control:last-child {
    border-left: gray;
    background-color: #ffffff;
    border: 1px solid #dddddd;
}

.p-datatable {
    font-family: "Poppins", sans-serif !important;
}

.p-datatable .p-datatable-thead > tr {
    border-radius: 10px;
}

.p-datatable .p-datatable-thead > tr > th {
    font-weight: 500;
    border: none;
    background-color: #fafafa !important;
    padding: 20px 15px !important;
}

.p-datatable .p-datatable-thead .p-column-title {
    color: #434ce6 !important;
}

.p-datatable .p-datatable-tbody > tr > td {
    padding: 10px 20px !important;
    background-color: #ffffff;
    border: none;
    color: #555555;
}

.p-datatable .p-datatable-tbody .p-datatable-emptymessage > td {
    text-align: center;
}

.actions-btn {
    width: 35px;
    height: 35px;
    background-color: #f8f8ff;
    box-shadow: none;
    border-radius: 10px;
    text-align: center;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.actions-btn .table-action-icon {
    font-size: 16px;
    color: #434ce6;
}

.actions-btn:hover {
    background-color: #f8f8ffb4;
}

.disabled-action-btn {
    background-color: #3f3f3f;
}

.disabled-action-btn .table-action-icon {
    color: #ffffff;
}

.disabled-action-btn:hover {
    background-color: #3f3f3f;
}

.load-more-button {
    background-color: #202020 !important;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    text-transform: none;
    margin: 0;
    text-align: center;
}

.load-more-button:hover {
    background-color: #343434 !important;
}

/* ------ Custom Primary Button ------ */
.custom-primary-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #434ce6;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    text-transform: none;
    margin: 0;
}

.custom-primary-button:hover {
    background-color: #434ce6 !important;
}

.custom-secondary-button {
    background-color: #3f3f3f;
}

.custom-secondary-button:hover {
    background-color: rgb(63, 63, 63) !important;
}

/* ------ Custom Primary Button ------ */

/* ------ Custom Minus/Plus Button ------ */
.minus-chip-btn,
.plus-chip-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 20px;
    padding: 0.2rem;
    margin: 0;
    background-color: #ebebeb !important;
    border: 1px solid #ebebeb;
    box-shadow: none;
    color: #666666;
    border: none;
    cursor: pointer;
}

.minus-chip-btn {
    border-radius: 10px 0 0 10px;
}

.plus-chip-btn {
    border-radius: 0 10px 10px 0;
}

.custom-chip-input {
    max-width: 200px;
    font-size: 14px;
    font-family: "DM Sans", sans-serif;
    border: 1px solid #ebebeb;
    padding: 0.2rem !important;
    height: 30px;
    width: 40px;
    margin: 0px !important;
    color: #666666;
    background-color: transparent !important;
    text-align: center;
    outline: none;
    -moz-appearance: textfield;
}

.custom-chip-input::-webkit-inner-spin-button,
.custom-chip-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Ensure the scrollbar rail is always visible */
.ps__rail-y {
    opacity: 1 !important; /* Make the rail always visible */
    visibility: visible !important; /* Ensure visibility */
    background-color: transparent !important; /* Hide the background */
    width: 8px !important; /* Custom width for the scrollbar rail */
    right: 0 !important; /* Position the rail correctly */
}

/* Customize the scrollbar thumb */
.ps__thumb-y {
    background-color: #888 !important; /* Custom color for the scrollbar thumb */
    width: 8px !important; /* Custom width for the scrollbar thumb */
    border-radius: 10px !important; /* Roundness for the scrollbar thumb */
    border: none !important; /* Remove any borders */
}

/* Ensure the rail has no background */
.ps__rail-y::before {
    content: "" !important;
    background-color: transparent !important;
}

/* Hide the scrollbar rail background initially */
.ps__rail-y:hover {
    background-color: transparent !important; /* Keep the background transparent on hover */
}

.fixed-plugin .dropdown-menu {
    top: -110px !important;
    right: 30px !important;
}

.fixed-plugin {
    top: 175px !important;
}

/* Customize the scrollbar rail if needed */

.file-upload-button {
    width: 260px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
    position: relative;
    background-color: white;
    border: 1px solid black;
    cursor: pointer; /* Ensures the cursor indicates a clickable item */
}

.file-upload-button:hover {
    background-color: white; /* Ensures background color stays the same on hover */
}
.modal-dialog {
    padding-top: 0px;
}
.react-tel-input .form-control {
    padding: 10px 10px 10px 10px;
}

.react-switch-bg {
    background-color: #434ce6 !important;
    background: #434ce6 !important;
}

.webchat--css-tilbt-j7qwjs webchat--css-tilbt-1eyrp0u {
    height: 380px !important;
}

.fixed-plugin .dropdown .dropdown-menu:before,
.fixed-plugin .dropdown .dropdown-menu:after {
    display: none !important;
}

.contact-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    transition: all 0.3s ease;
    background-color: #ffffff;
    position: relative;
    overflow: hidden;
    max-width: 100%; /* Ensure the card doesn't overflow */
}

.contact-card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    background-color: #f0f0f0;
}

.contact-icon {
    width: 50px;
    height: 50px;
}

a {
    text-decoration: none;
    color: inherit;
}

/* Center alignment for cards */
.content-dashboard-payment {
    max-width: 1100px; /* Adjust the width as per your layout */
    margin: 0 auto; /* Center the entire container */
}

.row.justify-content-center {
    display: flex;
    justify-content: center;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.8rem !important;
}

.custom-col {
    max-width: 19.95%; /* 2.5 columns of a 12-column grid is ~20.83% */
}

/* ------ Custom Minus/Plus Button ------ */

.tooltip-content {
    /* max-width: 250px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    margin: 0;
    font-weight: 600;
    color: #ffffff !important;
}

.tooltip-content .row {
    align-items: center;
}

.tooltip-content .key {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
}

.tooltip-content .key::after {
    content: ":";
}

.tooltip-content .key span {
    background-color: #ffffff;
    color: #555555;
    padding: 2px 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
}

.spinner {
    border: 2px solid #f3f3f3; /* Light gray */
    border-top: 2px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: spin 1s linear infinite;
    display: inline-block;
    margin-right: 5px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.menu-title-div {
    height: 50px;
    padding: 0px 10px; /* Adjust padding for spacing */
    /* margin: 20px 0 10px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
}

.menu-title-h {
    color: #434ce6; /* Black font color */
    font-weight: 600; /* Bold font */
    font-size: 1.2rem; /* Font size to make it more prominent */
    font-family: "DM Sans", sans-serif;
    margin: 0;
}

/* For screens smaller than 768px */
@media screen and (max-width: 768px) {
    .custom-col {
        max-width: 100%;
    }

    .menu-title-div {
        padding: 15px;
    }

    .menu-title-h {
        font-size: 1.05rem;
    }

    .fixed-plugin .dropdown-menu {
        max-width: 300px;
        right: 38px;
    }
}

.custom-login-btn-disabled {
    background-color: #c0c0c0; /* Gray for disabled */
    color: #6c757d; /* Light gray text */
    cursor: not-allowed;
    pointer-events: none;
    font-size: 16px;
    border-radius: 10px;
    outline: none;
    user-select: none;
    text-transform: none !important;
    white-space: normal;
    font-family: "DM Sans", Arial, sans-serif;
}

/* Custom Alert classes for Bootstrap [START] */
.custom-alert-class {
    max-width: 350px;
    padding: 20px 15px;
    padding-right: 50px;
    font-family: 1.2rem !important;
    font-weight: 500;
    border-width: 0 0 0 6px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px) !important;
    border-radius: 6px;
    user-select: none;
    font-family: "DM Sans", sans-serif;
}

.custom-alert-class button {
    background: transparent;
    box-shadow: none;
}

.custom-alert-class button span {
    font-size: 25px;
    font-weight: 500;
    color: #0f0f0f !important;
}

.alert-primary {
    background-color: rgba(219, 234, 254, 0.7) !important;
    color: #3b82f6;
    border: solid #3b82f6;
    border-width: 0 0 0 6px;
}

.alert-secondary {
    background-color: rgba(226, 227, 229, 0.7) !important;
    color: #343434;
    border: solid #343434;
    border-width: 0 0 0 6px;
}

.alert-success {
    background-color: rgba(228, 248, 240, 0.7) !important;
    color: #1ea97c;
    border: solid #1ea97c;
    border-width: 0 0 0 6px;
}

.alert-danger {
    background-color: rgba(255, 231, 230, 0.7) !important;
    color: #ff5757;
    border: solid #ff5757;
    border-width: 0 0 0 6px;
}

.alert-warning {
    background-color: rgba(255, 242, 226, 0.7) !important;
    color: #cc8925;
    border: solid #cc8925;
    border-width: 0 0 0 6px;
}

.alert-info {
    background-color: rgba(219, 234, 254, 0.7) !important;
    color: #3b82f6;
    border: solid #3b82f6;
    border-width: 0 0 0 6px;
}

.alert-light {
    background-color: rgba(255, 255, 255, 0.7) !important;
    color: #000000;
    border: solid #000000;
    border-width: 0 0 0 6px;
}

.alert-dark {
    background-color: rgba(33, 37, 41, 0.7) !important;
    color: #ffffff;
    border: solid #000000;
    border-width: 0 0 0 6px;
}

/* Custom Alert classes for Bootstrap [END] */

/* Help Center Button [START] */
.help-center-btn {
    position: fixed;
    top: 30%;
    transform: translateY(-30%);
    right: -50px;
    z-index: 9999;
    background-color: #434ce6;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: none;
    border-radius: 10px 10px 0 0;
    transform: rotate(-90deg);
}

.help-center-btn button {
    height: auto;
    line-height: normal;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
}
/* Help Center Button [END] */

/* ChatBot Custom Styles [START] */
.chatbot-card {
    border-radius: 20px;
    margin: 0;
}

.chatbot-card .card-header {
    background-color: #1b1b1b !important;
    color: #fff !important;
    font-weight: 600;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px 20px 0 0;
    border: none;
    font-family: "DM Sans", sans-serif;
}

.chatbot-card .card-header h5 {
    font-size: 1.1rem;
    margin: 0;
}

.chatbot-card .card-header button {
    background: transparent;
    color: #fff;
    margin: 0;
    padding: 0;
    border: none;
}

.chatbot-card .card-body button {
    outline: none !important;
    box-shadow: none !important;
}

.chatbot-card .card-body .webchat__icon-button:hover {
    background-color: transparent !important;
}

.webchat__icon-button {
    margin: 0;
    fill: #000000;
    transform: none !important;
    background-color: #ffffff !important;
}

.webchat__icon-button__shade {
    border-radius: 50px !important;
}

.webchat__send-box__main {
    border: 1px solid #e9e9e9;
    border-radius: 50px;
    background-color: #f5f5f5;
    padding: 0 10px;
    margin: 0;
}

.webchat__send-box-text-box {
    padding: 0 !important;
}

.webchat__send-box-text-box__input {
    font-size: 14px !important;
    border: none;
    box-shadow: none !important;
}

.webchat__bubble__content {
    font-size: 1rem !important;
    border: none !important;
    padding: 0 10px !important;
}
/* ChatBot Custom Styles [END] */

.paddingLRZero {
    padding-left: 0 !important;
    padding-right: 0 !important;
}