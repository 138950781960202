.download-popover-container {
    position: fixed;
    right: 20px;
    bottom: 80px;
    z-index: 1;
}

.download-popover .header {
    text-align: center;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    font-family: "DM Sans", sans-serif;
}

.popover-list {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 14px;
    font-weight: 500;
    color: #434343;
    margin: 5px 0;
    padding: 5px 0px;
    cursor: pointer;
    text-decoration: none;
    font-family: "DM Sans", sans-serif;
}

.item-csv {
    text-decoration: none;
    color: #434343 !important;
}

.item-csv:hover {
    text-decoration: none;
    color: #434343 !important;
}

.popover-list:hover {
    background-color: #f0f0f0 !important;
    border-radius: 5px;
}

.download-button {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #434ce6;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    padding: 0 !important;
}
