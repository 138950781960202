.navbar {
    height: 40px;

    .navbar-nav {
        .nav-link {
            padding: 0 0.7rem;
            font-family: "DM Sans", sans-serif;
            font-size: 1rem;
            color: #000000 !important;
            text-transform: none;

            i.fa + p,
            i.nc-icon + p {
                margin-left: 7px;
            }
        }
    }

    .navbar-collapse {
        .nav-item {
            a {
                font-size: 1rem;
                text-transform: none;
                color: #000000 !important;
                font-family: 'DM Sans', sans-serif;
            }
        }

        .active-nav-menu-item {
                color: #434CE6 !important;
        }

        .active-dropdown-menu-item {
          &>a {
            color: #434CE6 !important;
          }

          .active-nav-menu-item {
            background-color: #434CE6 !important;
            color: #FFFFFF !important;
          }
        }
    }

    .dropdown-menu {
      &::after,
      &::before {
        left: 12px;
        right: auto;
      }

      .dropdown-item {
        &:hover {
          background-color: #dee2e6;
          // color: #000 !important;
        }
      }
    }

    .navbar-toggler {
      padding: 0;
      border: none;
      box-shadow: none;
      margin: 0;
    }

    .navbar-toggler:focus {
      background: #FFFFFF;
      outline: none;
    }

    .collapsed {
      opacity: 1 !important;
      max-height: none !important;
    }

    .logout-button {
        // width: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        font-size: 14px;
        text-transform: none;
        font-family: "Poppins", "Helvetica Neue", sans-serif;
        background-color: $dark;
        margin-left: 20px;
        padding: 5px 15px;

        span {
            color: #ffffff;
        }

        svg {
          width: 20px;
          height: 20px;
        }

        @media screen and (max-width: 768px) {
            height: 35px;
            font-size: 12px !important;
            padding: 5px 10px !important;
        }

        @media screen and (max-width: 991px) {
        span {
              display: none;
          }
        }

        @media screen and (min-width: 768px) {
          width: 150px;
        }
    }

    @media screen and (max-width: 1024px) {
      height: auto;

      .navbar-nav {
        flex-direction: column;
        padding: 10px 0;
      }
    }
}
