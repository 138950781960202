body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content-dashboard {
  margin-top: 40px !important;
  padding: 0 15px 15px;
  min-height: calc(100vh - 140px);
}

.content-dashboard-createbill {
  margin-top: 55px !important;
  padding: 0 15px 15px;
  min-height: calc(100vh - 140px);
}

.content-dashboard-inventory {
  font-family: 'DM Sans', sans-serif;
  margin-top: 55px !important;
  padding: 0 15px 15px;
  min-height: calc(100vh - 140px);
}

.company-profile-container,
.content-dashboard-report {
  margin-top: 40px !important;
  padding: 0 15px 15px;
  min-height: calc(100vh - 140px);
  font-family: 'DM Sans', sans-serif;
}

.content-dashboard-notification {
  margin-top: 55px !important;
  padding: 0 15px 15px;
  min-height: calc(100vh - 140px);
}

.content-dashboard-payment {
  margin-top: 55px !important;
  padding: 0 15px 15px;
  min-height: calc(100vh - 140px);
}

.content-dashboard-report2 {
  margin-top: 30px !important;
  padding: 0 15px 15px;
  min-height: calc(100vh - 140px);
}

.content-dashboard-feedback {
  margin-top: 55px !important;
  padding: 0 15px 15px;
  min-height: calc(100vh - 140px);
}

.content-dashboard-stripe {
  margin-top: 55px !important;
  padding: 0 15px 15px;
  min-height: calc(100vh - 140px);
}

.content-dashboard-razorpay {
  margin-top: 55px !important;
  padding: 0 15px 15px;
  min-height: calc(100vh - 140px);
}

.content-dashboard-payment {
  margin-top: 40px !important;
  padding: 0 15px 15px;
  min-height: calc(100vh - 140px);
}

.poppins-title {
  font-family: 'Poppins', Helvetica, sans-serif;
}

.dashboard-container .p-dropdown .p-dropdown-label {
  font-family: 'Poppins', Helvetica, sans-serif;
}

.dashboard-title {
  color: #202020;
  font-size: 24px;
  font-weight: 600;
  text-transform: none;
}

.company-profile-container .title,
.content-dashboard-report .title {
  font-size: 1rem;
  font-weight: 500;
  color: #434CE6;
}

.company-profile-container .title a,
.content-dashboard-report .title a {
  color: #434CE6;
  /* text-decoration: none; */
}

.company-profile-container .card,
.content-dashboard-report .card {
  border-radius: 10px !important;
}

.top-selling-medicine-title,
.finance-analysis-title,
.recent-bills-title,
.recent-inventory-title {
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  color: #454545;
}

.recent-bills-title,
.recent-inventory-title {
  font-size: 18px;
  color: #454545;
}