.tooltip-box {
    background: #99B2C6;
    box-shadow: 0px 4px 7px rgba(3, 2, 41, 0.07);
    border-radius: 10px;
    padding: 5px 25px;
    color: white;
}

.sale-text {
    font-size: 12px;
}

.sale-value {
    font-size: 16px;
}

.total-product-box .ant-card-body {
    padding: 0;
}

.dropbox-circle {
    border-radius: 50%;
    height: 65px;
    width: 65px;
    background-color: #EAEAEA;
    display: flex;
    align-items: center;
    justify-content: center
}

.dropbox-icon {
    font-size: 25px;
    color: #3A36DB;
}

.cart-circle {
    border-radius: 50%;
    height: 65px;
    width: 65px;
    background-color: #FF69B4;
    display: flex;
    align-items: center;
    justify-content: center
}

.cart-icon {
    font-size: 25px;
    color: #f0e130;
}

.total-product-title {
    font-size: 15px;
    color: #06152B;
}

.total-product-value {
    font-size: 25px;
    color: #06152B;
}

.new-added {
    color: #03A89E;
    font-size: 12px;
}

.circle {
    border: 1px solid;
    border-radius: 50%;
    height: 77px;
}

.product-history-step {
    height: 450px
}

.product-history-step .title {
    font-size: 24px;
}

.product-history-step .description {
    font-size: 20px;
}

.product-history-step .ant-steps-item-tail {
    left: 47px !important;
    position: absolute !important;
    top: 7px !important;
    padding: 70px 0 6px !important;
}

.product-history-step .ant-steps-item-icon {
    width: 95px;
}

.product-history-icon {
    font-size: 50px;
    margin-top: 12px;
}

.product-history-step .ant-steps-item-content {
    padding-left: 35px;
    padding-top: 5px;
}

.product-history-title {
    text-align: center;
}

.product-history-title .ant-card-head-title {
    font-size: 32px !important;
    padding: 25px;
}

.product-detail-title .ant-card-head-title {
    font-size: 32px !important;
    padding: 15px;
}

.barcode-text {
    font-size: 21px;
    color: #B6B5B5;
}

.input-box,
.input-box .ant-select-selector {
    height: 45px !important;
}

.input-label {
    font-size: 16px;
    color: #083A50;
}

/* .date-filter {
    border: 1px solid #083A50;
} */

.date-filter-menu {
    padding: 8px 16px;
    border: 1px solid rgba(25, 118, 210, 0.5);
}

.report-title {
    font-size: 20px;
    color: #06152B;
}

.menu-item {
    color: #4B5563;
    font-size: 20px;
}

.p-row-odd {
    background-color: #e6f1fc !important;
}

.p-column-title {
    color: #216ec1 !important;
}

.p-component {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;
    font-weight: 400 !important;
}

.text-mesage {
    font-size: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.p-autocomplete-input {
    font-size: 16px !important;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important
}


/* new UI changes */
.content-dashboard-inventory .card {
    border-radius: 10px;
}

.title-route-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* .content-dashboard-inventory .title-route-container {
    margin-bottom: 10px;
} */

.content-dashboard-inventory .title {
    font-size: 1rem;
    font-weight: 500;
    color: #434CE6;
}

.content-dashboard-inventory h4 {
    margin: 0;
}

.inventory-product-card {
    border-radius: 10px !important;
}

.inventory-data-table-card-body {
    padding: 10px 0 !important;
}

.payment-list-action-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.payment-list-action-col svg {
    color: #434CE6;
}

.inventory-card-header {
    width: 100%;
    color: #000000;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.inventory-card-header h5 {
    font-family: 'DM Sans', sans-serif !important;
    font-weight: 600;
    margin: 0;
}

.custom-input-container .textarea {
    padding: 10px;
    color: #000000 !important;
    border: 1px solid #D0CECE;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
}

.inventory-discount-control {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inventory-discount-control .manual-discount-input {
    width: 100%;
    border: 1px solid #D0CECE;
    border-radius: 10px 0 0 10px;
    padding: 0.5rem;
    background-color: transparent;
    color: #666666;
    /* text-align: center; */
    outline: none;
    -moz-appearance: textfield;
}

.inventory-discount-control .type-dropdown {
    font-family: 'DM Sans', sans-serif;
    border-radius: 0 10px 10px 0;
    padding: 0.6rem 0.2rem;
    background-color: #E9EAFF !important;
    color: #666666;
    border: none;
    cursor: pointer;
}

.dropbox-content-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.dropbox-upload-icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 50px;
    color: #434CE6;
}

/* Stepper Style Start */
.inventory-stepper-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
}

.inventory-step-item {
    width: 30%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inventory-step-item:not(:first-child):before {
    content: "";
    /* background-color: #BCBCBC; */
    position: absolute;
    width: 100%;
    height: 3px;
    border-top: 3px dashed #BCBCBC;
    right: 50%;
    top: 25%;
    transform: translateY(-50%);
}

.inventory-step {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
    border-radius: 100%;
    font-weight: 600;
    color: #FFFFFF;
    background-color: #BCBCBC;
}

.inventory-active-step .inventory-step {
    color: #FFFFFF;
}

.inventory-complete-step .inventory-step {
    color: #FFFFFF;
}

.inventory-complete-step p {
    color: #6F6F6F;
}

.inventory-complete-step:not(:first-child):before,
.inventory-active-step:not(:first-child):before {
    border-top: 3px solid #434CE6;
}

.inventory-active-step:not(:last-child) .inventory-step,
.inventory-complete-step:not(:last-child) .inventory-step {
    background-color: #434CE6;
}

.inventory-complete-step:not(:last-child) p,
.inventory-active-step:not(:last-child) p {
    color: #434CE6;
}

/* Stepper Style End */


/* Custom Tab start */
.custom-tab-switch {
    position: relative;
}

.custom-tab-switch:after {
    content: "";
    position: absolute;
    width: 50%;
    top: 0;
    transition: left cubic-bezier(.88, -.35, .565, 1.35) .4s;
    border-radius: 8px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .1);
    background-color: #434CE6;
    height: 100%;
    z-index: 0;
}

.custom-tab-switch.left:after {
    left: 0;
}

.custom-tab-switch.right:after {
    left: 50%;
}

.custom-tab-switch .custom-tab {
    display: inline-block;
    color: #4B5563;
    width: 50%;
    padding: 10px 0;
    position: relative;
    font-size: 14px;
    user-select: none;
    z-index: 1;
    cursor: pointer;
    transition: color 200ms;
}

.custom-tab-switch .custom-tab.custom-active-tab {
    color: #ffffff;
}

.custom-tab-wrapper {
    border-radius: 10px;
    border: 1px solid #f4f4f4;
    background-color: #FFFFFF;
    padding: 5px;
    width: 100%;
    max-width: 400px;
    text-align: center;
}

/* Custom Tab end */

.product-name-autocomplete input {
    background-color: #FFFFFF !important;
}

.inventory-image-preview {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.inventory-img-container {
    position: relative;
    max-width: 50%;
    width: 100%;
    height: 150px;
}

.inventory-img-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.remove-inventory-img-btn {
    position: absolute;
    color: #666666;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.inventory-read-only-values label{
    color: #444444 !important;
    font-size: 0.9rem !important;
    font-weight: 500 !important;
    margin: 0 !important;
}

.inventory-read-only-values .value-cell {
    height: 40px;
    display: flex;
    justify-self: flex-start;
    align-items: center;
    color: #000000;
    font-weight: 500;
}

@media only screen and (max-width: 600px) {
    .custom-tab-wrapper {
        max-width: 100%;
    }

    .inventory-stepper-container {
        width: 100%;
    }
}