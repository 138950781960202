.card.templates-table-card{
    border-radius: 10px !important;
    padding: 10px 0 !important;
    font-family: 'DM Sans', Arial, sans-serif;   
}

.templates-table-card .title-row {
    padding: 0px 20px 10px;
}

.tamplate-list {
    font-family: 'DM Sans', Arial, sans-serif;
}

.templates-table {
    width: 100%;
    text-align: center;
    font-family: 'DM Sans', Arial, sans-serif;
    margin-bottom: 10px;
}

.templates-table thead th {
    padding: 10px 0;
    font-size: 16px;
    color: #434CE6;
    background-color: #FBFBFB;
    border-top: 1px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
}

.templates-table tbody td {
    padding: 10px;
    font-size: 14px;
    color: #444444;
    border-bottom: 1px solid #E8E8E8;
}

.template-id-col {
    min-width: 100px;
    width: 20%;
}

.template-name-col {
    min-width: 200px;
    width: 30%;
}

.template-type-col {
    min-width: 150px;
    width: 20%;
}

.template-edit-col {
    min-width: 100px;
    width: 15%;
}

.template-action-col {
    min-width: 100px;
    width: 15%;
}
