.invoice-header {
  text-align: center;
  font-weight: bold;
  margin-top: 10px !important;
}

.invoice-header2 {
  text-align: center;
  font-weight: 500;
}

.header-bold {
  font-weight: bold;
  font-size: 18px;
}
.header-val {
  font-size: 18px;
}

/* CustomTable.css */

/* .custom-table {
  width: 100%;
  border-collapse: collapse;
 
  font-size: 18px;
  text-align: left;
}

.custom-table-font {
  
  font-size: 11px;
  margin: 0px !important;
}

.custom-table tr{
  height: 30px !important;
}

.custom-table th {
  border: 1px solid #dddddd;
  padding: 3px 5px;
  text-align: center;
  font-size: 14px;
}

.custom-table td {
  border: 1px solid #dddddd;
  padding: 0px 5px;
  text-align: center;
}

.custom-table th {
  background-color: #f2f2f2;
} */

/* .custom-table tr:nth-child(even) {
  background-color: #f3f3f3;
} */
/* 
.custom-table tr:hover {
  background-color: #ddd;
} */

.invoice-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
}
.invoice-content {
  flex: 1;
}

.summary-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 20px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.summary-key {
  flex: 1;
  text-align: left;
}

.summary-value {
  flex: 1;
  text-align: left;
}

.fixed-width-right-align {
  width: 100px; /* You can adjust this width */
  text-align: right;
  display: inline-block;
}

.monospace-right-align {
  font-family: "Courier New", monospace;
  text-align: right;
}

.center-div {
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.right-align-text {
  text-align: right !important;
}

.custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 18px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  overflow: hidden;
  background-color: #ffffff;
}

.custom-table-font {
  font-size: 11px;
  margin: 0;
  font-family: "Inter", sans-serif;
}

.custom-table th,
.custom-table td {
  padding: 3px 5px;
  text-align: center;
  border: 1px solid #e0e0e0;
}

.custom-table-2 th,
.custom-table-2 td{
  padding: 0px 5px !important;
  border:none;
}
.custom-table-2 th{
  background-image: linear-gradient(to bottom, #ffb400, hsl(32, 100%, 50%));
  font-weight: 400;
}
.custom-table-2 td {
  font-size: 10px !important;
  font-weight: none !important;
  border-bottom: 1px solid rgb(232, 230, 230);
  background-color: #ffffff !important;
  padding: 1% 0% !important;
}
.custom-table-2 tr{
  border: 1px solid grey;
}

.custom-table th {
  background-color: #005792;
  color: #ffffff;
  font-size: 14px;
}

.custom-table td {
  color: #333333;
  background-color: #fafafa;
  padding: 10px 5px;
}

/* Enhanced hover selector to ensure it applies */
.custom-table tbody tr:hover td {
  background-color: #f0f8ff !important;
  transition: background-color 0.3s ease;
}

.custom-table th:first-child,
.custom-table td:first-child {
  border-left: 1px solid #e0e0e0;
}

.custom-table th:last-child,
.custom-table td:last-child {
  border-right: 1px solid #e0e0e0;
}

.custom-table tr:first-child th {
  border-top: 1px solid #e0e0e0;
}

.custom-table tr:last-child td {
  border-bottom: 1px solid #e0e0e0;
}

.cutomize-form-control-input {
  margin: 0px 0px 0px 0px !important;
}
@media (max-width: 768px) {
  .cutomize-form-control-input {
    min-width: 50px !important;
  }
  .custom-table th{
    min-width: 80px !important;
  }
  .custom-table th:first-child{
    min-width: 120px !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .cutomize-form-control-input {
    min-width: 50px !important;
  }
  .custom-table th{
    min-width: 80px !important;
  }
  .custom-table th:first-child{
    min-width: 120px !important;
  }
}

.cutomize-form-control .form-control {
  margin: 0px;
}

/* Invoice header */
/* .invoice-header {
  background-color: #f8f9fa;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #dee2e6;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo {
  height: 60px;
  margin-right: 20px;
}

.company-info h1 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.company-info p {
  margin: 5px 0;
  font-size: 16px;
  color: #666;
}

.invoice-info h2 {
  margin: 0 0 10px 0;
  font-size: 20px;
  color: #333;
}

.invoice-info p {
  margin: 4px 0;
  font-size: 16px;
  color: #666;
}
 */

.invoice-header {
  background-color: #1e2a30;
  color: #ffffff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 0px;
  margin-top: -5px;
  margin-bottom: 15px;
}

.header-content {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 20px;
  align-items: center;
}

.logo-container img {
  width: auto;
  max-height: 60px;
  height: auto;
}

.company-info h1 {
  margin: 0;
  color: #4dd0e1;
  font-size: 24px;
}

.company-info p {
  margin: 5px 0;
  color: #a0b9c6;
  font-size: 14px;
}

.invoice-details h5 {
  margin: 0;
  color: #4dd0e1;
  font-weight: normal;
}

.invoice-details span {
  font-weight: bold;
}

.invoice-details p {
  margin: 5px 0;
  color: #a0b9c6;
  font-size: 14px;
}

.logo-container {
  justify-self: start;
}

.company-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.invoice-details {
  justify-self: end;
  text-align: left;
}
.invoice-footer {
  background-color: #f7f7f7;
  color: #4a4a4a;
  padding: 20px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 14px;
}

.footer-left,
.footer-right {
  display: flex;
  flex-direction: column;
}

.footer-thank-you {
  font-size: 16px;
  font-weight: bold;
  color: #017374;
  margin-bottom: 10px;
}

.footer-address {
  font-size: 14px;
  margin-bottom: 15px;
}

.footer-right p {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 5px 0;
}

.icon {
  width: 24px;
  margin-right: 10px;
  vertical-align: middle;
}

.footer-right p:hover {
  color: #017374;
  cursor: pointer;
}
